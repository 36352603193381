<style>
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}

.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}

.full-modal .ant-modal-body {
  flex: 1;
}

</style>
<style scoped>
/*:deep(.ant-modal-close){*/
/*  position: absolute;*/
/*  left: 0 !important;*/
/*  top: 10px;*/
/*}*/
:deep(.ant-modal-close) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  width: 3.5%;
}
div :deep(.ant-modal-footer){
  position: absolute;
  right: 0;
}
:deep(.ant-modal-body){
  overflow: scroll;
  /*height: 100px;*/
}
:deep(.ant-modal-title){
  text-indent: 2em;
}
</style>
<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addKeyword">
      <template #icon>
        <file-add-outlined />
      </template>
      新增规则
    </a-button>
    <a-button type="primary" @click="importRule">
      <template #icon>
        <upload-outlined />
      </template>
      导入规则
    </a-button>
    <a-button danger @click="delElement('sel')">
      <template #icon>
        <DeleteOutlined />
      </template>
      批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="tablePaginationChange"
    :loading="loading"
    :row-key="(record) => record.id"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-space>
          <a-button type="primary" @click="editElement(record)" size="small">
            <template #icon>
              <form-outlined />
            </template>
            编辑
          </a-button>
          <a-button
            type="primary"
            @click="handelViewScript(record)"
            size="small"
          >
            <template #icon>
              <eye-outlined />
            </template>
            查看
          </a-button>
          <a-button
            type="primary"
            @click="delElement(record)"
            size="small"
            danger
          >
            <template #icon>
              <delete-outlined />
            </template>
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <div ref="myModal">
    <a-modal
      cancelText="关闭"
      okText="保存"
      :get-container="()=>$refs.myModal"
      v-model:visible="addShow"
      width="100%"
      wrap-class-name="full-modal"
      @cancel="onClose"
      destroyOnClose
      title="规则录入"
      @ok="handleOk"
    >
      <!--    <div style="line-height: 32px; height: 32px; padding: 0 10px">-->
      <!--      <label style="display: inline-block; width: 120px">规则名称:</label>-->
      <!--      <div style="display: inline-block; width: calc(50% - 240px)">-->
      <!--        <a-input v-model:value="scriptData.scriptName"></a-input>-->
      <!--      </div>-->
      <!--      <label style="display: inline-block; width: 120px;margin-left: 50px">接口类型:</label>-->
      <!--      <div style="display: inline-block; width: calc(50% - 240px)">-->
      <!--&lt;!&ndash;        <a-input v-model:value="scriptData.scriptName"></a-input>&ndash;&gt;-->
      <!--        <a-select-->
      <!--          v-model:value="scriptData.ruleType"-->
      <!--          placeholder="请选择"-->
      <!--          style="width: 100%"-->
      <!--          :options="intrefaceTypeIdList"-->
      <!--        ></a-select>-->
      <!--      </div>-->
      <!--    </div>-->
      <a-form
        :model="scriptData"
        layout="vertical"
        ref="formRef"
        autocomplete="off"
      >
        <a-row :gutter="10">
          <a-col span="12">
            <a-form-item label="规则名称:" name="scriptName" :rules="[{ required: true, message: '请输入规则名称' ,trigger: 'blur'}]">
              <a-input v-model:value="scriptData.scriptName"></a-input>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="接口类型:" name="ruleType" :rules="[{ required: true, message: '请选择接口类型' ,trigger: 'change'}]">
              <a-select
                v-model:value="scriptData.ruleType"
                placeholder="请选择"
                :options="intrefaceTypeIdList"
              ></a-select>
            </a-form-item>
          </a-col>

        </a-row>
      </a-form>
      <ScriptEditor :ruleText="scriptData.originalText" ref="scriptRef" />
    </a-modal>
  </div>


  <ImportRules ref="ruleImportRef" @onSave="handleSearch"></ImportRules>
  <!--  <a-modal v-model:visible="addShow2" @cancel="onClose" destroyOnClose-->
  <!--           title="规则录入" @ok="handleOk">-->
  <!--    <a-row :gutter="[16,16]">-->
  <!--      <a-col :span="8" style="line-height: 32px;text-align: right;">-->
  <!--        规则名称:-->
  <!--      </a-col>-->
  <!--      <a-col :span="12">-->
  <!--        <a-input v-model:value="scriptData.scriptName"></a-input>-->
  <!--      </a-col>-->
  <!--      <a-col :span="8" style="line-height: 32px;text-align: right;">-->
  <!--        规则地址:-->
  <!--      </a-col>-->
  <!--      <a-col :span="12">-->
  <!--        <a-input v-model:value="scriptData.scriptText"></a-input>-->
  <!--      </a-col>-->
  <!--      <a-col :span="24" style="text-align: center">-->
  <!--        <a-upload-->
  <!--          v-model:file-list="fileList"-->
  <!--          name="file"-->
  <!--          :headers="headers"-->
  <!--          @change="uploadChange"-->
  <!--          :max-count="1"-->
  <!--          :customRequest="importScript"-->
  <!--        >-->
  <!--          <a-button type="primary">-->
  <!--            <upload-outlined></upload-outlined>-->
  <!--            导入规则文件-->
  <!--          </a-button>-->
  <!--        </a-upload>-->
  <!--      </a-col>-->
  <!--    </a-row>-->
  <!--  </a-modal>-->
  <a-modal
    v-model:visible="detailShow"
    cancelText="关闭"
    destroyOnClose
    title="脚本详情"
    @ok="handleDetailOk"
  >
    <p>{{ scriptText }}</p>
  </a-modal>
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import { defineComponent, reactive, onMounted, toRefs, computed, ref } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request'
import ScriptEditor from '../../bee_rule_engine/script_editor.vue'
import store from '../../../store'
import Axios from 'axios'
import ImportRules from './importRules'

export default defineComponent({
  components: {
    ImportRules,
    SearchForm,
    ScriptEditor,
  },
  setup () {
    const ruleImportRef = ref()
    const state = reactive({
      fileList: [],
      intrefaceTypeIdList:[],
      headers: {
        'Content-Type': 'multipart/form-data',
        beeId: store.getters['user/getBeeId'] || '',
        userId: store.getters['user/getUserId'] || '',
        loginId: store.getters['user/getLoginId'] || '',
        corpId: store.getters['user/getCorpId'] || ''
      },
      addShow: false,
      addShow2: false,
      detailShow: false,
      scriptText: '',
      searchNew: {},
      selectedRowKeys: [],
      condition: {
        scriptName: {
          label: '规则名称',
        },
        ruleType:{
          label: '接口类型',
          type: 'select',
          default: '1',
          data: [],
        }
      },
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'left',
        },
        {
          title: '接口类型',
          width: 240,
          key: 'ruleTypeName',
          dataIndex: 'ruleTypeName',
        },
        {
          title: '规则名称',
          width: 240,
          key: 'scriptName',
          dataIndex: 'scriptName',
        },
        {
          title: '规则id',
          width: 60,
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '规则集名称',
          key: 'collectionName',
          dataIndex: 'collectionName',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 120,
          dataIndex: 'operation'
        },
      ],

      scriptData: {
        id: null,
        scriptName: '',
        scriptText: '',
        originalText: '', // 增加一个原文
        scriptType: 0,
        collectionId: null,
        collectionName: null,
        ruleType:undefined
      }


    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ruleScript_list'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const huoqu = () => {
      const uri = getRequestURI('getProductInterface')
      request(uri, {data: {}}).then(res => {
        if (res.code === 1) {
          state.intrefaceTypeIdList=res.result
          state.intrefaceTypeIdList.forEach(item=>{
            item.label=item.name
            item.value=item.id
          })
          state.condition.ruleType.data=state.intrefaceTypeIdList
        }

      })
    }
    huoqu()
    //查询
    const handleSearch = (filters = 'reload') => {
      if (filters !== 'reload') {
        state.searchNew = filters
      }
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: state.searchNew,
      })
    }
    // 页码变化
    const tablePaginationChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNew,
      })
    }
    // 选择
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const adAndUpdateRef = ref()
    // 新增
    const addKeyword = () => {
      state.scriptData.id = null
      state.scriptData.scriptName = ''
      state.scriptData.scriptText = ''
      // 增加一个原文
      state.scriptData.originalText = ''
      state.scriptData.scriptType = 0
      state.scriptData.collectionId = null
      state.scriptData.collectionName = null
      state.scriptData.ruleType = undefined
      state.addShow = true
    }
    // 删除
    const delElement = (recode) => {
      let ids = []
      if (recode !== 'sel') {
        ids.push(recode.id)
      } else {
        if (!state.selectedRowKeys.length) {
          return message.warning('请选择数据')
        }
        ids = [...state.selectedRowKeys]
      }
      Modal.confirm({
        title: '删除规则',
        content: recode !== 'sel' ? '是否删除规则"' + recode.scriptName + '"？' : '是否删除已选择的规则？',
        onOk: () => {
          request(getRequestURI('ruleScript_delete'), { ids }).then(res => {
            if (res.code === 1) {
              handleSearch(state.searchNew)
              state.selectedRowKeys = []
              message.success('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const scriptRef = ref()
    //编辑
    const editElement = (data) => {
      Object.keys(data).forEach(key => {
        state.scriptData[key] = data[key] || ''
      })
      state.addShow = true
    }
    // 查看脚本
    const handelViewScript = (data) => {
      state.scriptText = data.scriptText
      state.detailShow = true
    }
    // 脚本详情的确定按钮
    const handleDetailOk = () => {
      state.detailShow = false
    }

    // 录入确定
    const handleOk = () => {
      console.log('执行了保存')
      if(!state.scriptData.scriptName){
        return message.warning('规则名称不能为空')
      }
      if(!state.scriptData.ruleType){
        return message.warning('接口类型不能为空')
      }
      const scriptObj = scriptRef.value.getRunStr()
      if (scriptObj === undefined || scriptObj === null || scriptObj.runStr === '') {
        return message.warning('规则内容不能为空!')
      }
      if (state.scriptData.scriptName === undefined || state.scriptData.scriptName === null || state.scriptData.scriptName === '') {
        return message.warning('规则名称不能为空')
      }
      state.scriptData.scriptText = scriptObj.runStr
      state.scriptData.originalText = scriptObj.originalStr
      state.scriptData.scriptType = 0
      // let uri = '/bee-rule-service/rule/ruleScript/api/add'
      // request(uri,{...state.scriptData}).then(
      request(getRequestURI('ruleScript_add'), { ...state.scriptData }).then(
        res => {
          console.debug(res)
          if (res.code === 1) {
            handleSearch(state.searchNew)
            onClose()
            message.success('保存成功')
          } else {
            message.warning(res.message)
          }
        }
      )
    }
    const onClose = () => {
      console.log('执行了关闭')
      state.addShow = false
      state.addShow2 = false
      state.detailShow = false
      state.scriptData.id = null
    }
    /**
     * 导入av文件
     */
    const importRule = () => {
      // state.addShow2 = true
      ruleImportRef.value.showImportRuleDrawer(1)
    }

    /**
     * 导入av文件
     */
    const importScript = (data) => {
      if (!state.scriptData.scriptName || state.scriptData.scriptName == '') {
        return message.warn('请输入规则名称')
      }
      // todo 这里需要补全导入文件逻辑
      // todo 判断文件后缀名是否为.av,不是则不允许上传，防止后端逻辑校验出错
      console.debug('导入文件')
      const param = new FormData()
      param.append('file', data.file)
      param.append('id', state.scriptData.id)
      param.append('name', state.scriptData.scriptName)
      let config = {
        headers: state.headers
      }
      Axios.post('/backend/bee-rule-service/rule/ruleScript/api/import', param, config).then((res) => {
        console.debug('导入文件res', res)
        if (res.code == 1) {
          state.scriptData.scriptName = res.result.scriptName
          state.scriptData.scriptText = res.result.scriptText
          state.scriptData.scriptType = res.result.scriptType
          state.scriptData.id = res.result.id
          state.scriptData.originalText = ''
          message.info(res.msg)
        } else {
          message.error(res.msg)
        }
      })
    }
    const uploadChange = (info) => {
      console.debug('******')
      console.debug(JSON.stringify(info))
    }
    return {
      ...toRefs(state),
      handleSearch,
      tablePaginationChange,
      addKeyword,
      importRule,
      onSelectChange,
      delElement,
      editElement,
      handleOk,
      onClose,
      handleDetailOk,
      handelViewScript,
      importScript,
      uploadChange,
      adAndUpdateRef,
      pagination,
      dataSource,
      loading,
      scriptRef,
      ruleImportRef
    }
  }
})
</script>

