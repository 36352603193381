<style scoped>
.monitor-content {
  height: calc(100% - 10px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

</style>

<template>
  <div class="monitor-content">
    <a-list item-layout="vertical" :bordered="true">
      <template v-for="(item, index) in dtt" :key="item['taskId'] + '_' + index">
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              <setting-outlined style="margin-left: 20px;" @click="showModel(item)"/>
              <label style="margin-left: 20px;">{{ item['taskName'] }}</label>
            </template>
          </a-list-item-meta>
          <div>
            <a-divider style="margin: 10px;" dashed/>
            <template v-for="(item2, index2) in item.taskInstances" :key="item['taskId'] + '_taskInstances_' + index2">
              <label style="display: inline-block; width: 33%;">
                <a-checkbox v-model:checked="item2['value']"
                            @change="changeInstanceStatus(item, item2)">{{ item2['instanceName'] }}
                </a-checkbox>
              </label>

            </template>
          </div>
        </a-list-item>
      </template>
    </a-list>
    <a-modal width="700px" v-model:visible="selectTask" @cancel="onLabelCancel" @ok="onLabelSave"
             :closable="false" :mask-closable="false"
             :title="currentTask.taskName">
      <div>
        <div style="display:flex;">
          <div class="chose-company" style="width:200px;">
            公司名称
          </div>
          <div style="margin-left: 210px; width: 350px;">
            <a-input v-model:value="taskCron" placeholder="请输入表达式" @change='allTaskCron'></a-input>
          </div>
          <div style="margin-left: 90px;">
            <a-switch v-model:checked="taskStatus" checked-value="ON" un-checked-value="OFF" @change="changeStatus()">
            </a-switch>
          </div>
        </div>
        <div v-for="(item,index) in allCompany" :key="index" style="display:flex;margin-top:20px;">
          <div style="width:400px;">{{ item.corpName }}</div>
          <div style="margin-left: 90px; width: 350px;">
            <a-input v-model:value="item.taskCron"></a-input>
          </div>
          <div style="margin-left: 90px;">
            <a-switch v-model:checked="item.taskStatus" checked-value="ON" un-checked-value="OFF">
            </a-switch>
          </div>
        </div>
      </div>

    </a-modal>
  </div>
</template>

<script>

import {reactive, defineComponent, toRefs, onBeforeMount} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  name: 'dtt',
  setup() {
    const state = reactive({
      dtt: [],
      selectTask: false,
      choseAll: false,
      taskStatus: 'OFF',
      taskCron: '',
      taskTitle: '',
      allCompany: [],
      currentTask: {},
      choseCompany: []
    })
    const changeInstanceStatus = function (dtt, dtti) {
      console.debug('changeInstanceStatus', dtti)
      request(getRequestURI('dtt_change2'), {
        taskId: dtt.taskId,
        taskInstances: [{
          instanceStatus: dtti.value ? 'ON' : 'OFF',
          instanceId: dtti.instanceId,
          instanceName: dtti.instanceName
        }]
      }).then(res => {
        if (res.code === 1) {
          message['success']({
            background: true,
            duration: 1,
            content: dtt.taskName + '的实例' + dtti.instanceName + '设置成功'
          })
        } else {
          message['warning']({
            background: true,
            duration: 1,
            content: dtt.taskName + '的实例' + dtti.instanceName + '设置失败'
          })
        }
      })
    }
    const changeStatus = function () {

      if (state.taskStatus === 'ON') {
        state.allCompany.forEach(item => {
          item.taskStatus = 'ON'
        })
      } else {
        state.allCompany.forEach(item => {
          item.taskStatus = 'OFF'
        })
      }
    }
    const allTaskCron = function () {
      state.allCompany.forEach(item => {
        item.taskCron = state.taskCron
      })
    }
    const changeCron = function (dtt) {
      request(getRequestURI('dtt_change_cron'), {
        taskId: dtt.taskId,
        taskName: dtt.taskName,
        taskStatus: dtt.taskStatus,
        taskCron: dtt.taskCron,
        taskInstances: null
      }).then(res => {
        if (res.code === 1) {
          message['success']({
            background: true,
            duration: 1,
            content: dtt.taskName + '执行计划设置成功'
          })
        } else {
          message['warning']({
            background: true,
            duration: 1,
            content: dtt.taskName + '执行计划设置失败'
          })
        }
      })
    }
    const init = function () {
      request(getRequestURI('dtt_init')).then(res => {
        if (res.code === 1) {
          state.dtt = res.result
          console.debug(state.dtt)
          state.dtt.forEach((task) => {
            task['taskInstances'].forEach((instance) => {
              instance['value'] = instance['instanceStatus'] === 'ON' ? true : false
            })
          })
        }
      })
    }
    // 打开弹窗
    const showModel = function (item) {
      state.selectTask = true
      state.currentTask = item
      request(getRequestURI('getCorpList'), {taskId: item.taskId}).then(res => {
        if (res.code === 1) {
          state.allCompany = res.result
        }
      })
    }
    // 关闭
    const onLabelCancel = function () {
      state.taskCron = ''
      state.selectTask = false
      state.allCompany = []
    }
    // 全选与取消全选
    const choseAllTask = function () {
      if (state.choseAll) {
        state.allCompany.forEach(item => {
          item.chose = true
        })
        state.choseCompany = state.allCompany
      } else {
        state.allCompany.forEach(item => {
          item.chose = false
        })
        state.choseCompany = []
      }
    }
    const onLabelSave = function () {
      request(getRequestURI('dtt_change_cron'), {
        list: state.allCompany
      }).then(res => {
        if (res.code === 1) {
          state.selectTask = false
        }
      })
    }
    const choseTaskItem = function (index) {
      if (state.allCompany[index].chose) {
        state.choseCompany.push(state.allCompany[index])
      } else {
        state.choseCompany.forEach((res, num) => {
          if (res.chose === state.allCompany[index].chose) {
            state.choseCompany.splice(num, 1)
          }
        })
      }
    }
    onBeforeMount(() => {
      init()
    })
    return {
      ...toRefs(state),
      changeInstanceStatus,
      changeStatus,
      allTaskCron,
      changeCron,
      showModel,
      onLabelCancel,
      onLabelSave,
      choseTaskItem,
      choseAllTask,
    }
  }
})
</script>
