<style scoped>
.column-title {
  background: #e0e0e0;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content_scroll {
  width: 100%;
  height: calc(100% - 54px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.role_item {
  margin: 10px 10px 0 10px;
  line-height: 36px;
  background-color: #ebebeb;
  text-indent: 10px;
  position: relative;
}
.role_item p {
  border: 1px solid #ebebeb;
  color: #b3b3b3;
  background-color: #fff;
}
.role_item button {
  position: absolute;
  right:10px;
  top: 6px;
}
.role_title {
  padding-left: 10px;
}
</style>

<template>
  <div class="column-title">
    <span>角色列表</span>
    <span>
      <a-button shape="circle" @click="addRole" :loading="loading">
        <template #icon> <PlusOutlined/> </template>
      </a-button>  <a-button shape="circle" v-show="selectRoles.length !== 0" @click="delConfirm" :loading="loading">
        <template #icon> <DeleteOutlined/> </template>
      </a-button>
    </span>
  </div>
  <a-checkbox-group class="content_scroll" v-model:value="selectRoles" >
    <template v-for="role in roleInfos" :key="'role_' + role.id">
      <div class="role_item">
        <a-checkbox :value="role.id"></a-checkbox>
        <span class="role_title" :style="{color: activeRoleId === role.id ? '#2d8cf0' : '#515a6e',cursor: 'pointer'}"
              @click="selectRoleInfo(role)">{{ role.roleName }}</span>
        <a-button type="primary" size="small" @click="editRole(role)" :loading="loading">
          <template #icon> <EditOutlined/> </template>
        </a-button>
        <p>{{ role.remark }}</p>
      </div>
    </template>
  </a-checkbox-group>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request'

export default defineComponent({
  components: {},
  emits: ['getRoleId','get-role-id','editRole','edit-role'],
  setup(props, context) {
    const state = reactive({
      loading: false,
      activeRoleId: '',
      selectRoles: [],
      roleInfos: []
    })
    const selectRoleInfo = (_role) => {
      state.activeRoleId = _role.id
      context.emit('getRoleId', _role)
    }
    const addRole = () => {
      context.emit('editRole')
    }
    const editRole = (_role) => {
      context.emit('editRole', _role)
    }
    const delConfirm = () => {
      if (state.selectRoles.length === 0) {
        message['error']('请选择需要删除的角色')
      } else {
        Modal.confirm({
          title: '删除角色',
          content: '是否确认删除',
          onOk: () => {
            delRoles()
          },
          onCancel: () => {
            Modal.destroyAll()
          }
        })
      }
    }
    const delRoles = () => {
      state.loading = true
      request(getRequestURI('role_delete'), {data: {roleIds: state.selectRoles}}).then(result => {
        state.loading = false
        if (result.code === 1) {
          state.selectRoles.length = 0
          roleInit()
          message['success']('删除角色成功')
        } else {
          message['error'](result.message)
        }
      })
    }
    const roleInit = () => {
      state.roleInfos = []
      request(getRequestURI('role_selectList'), {data:{}}).then(res => {
        state.roleInfos = res.result
      })
    }


    roleInit()

    context.expose({
      roleInit
    })
    return {
      ...toRefs(state),
      addRole,
      editRole,
      delConfirm,
      selectRoleInfo
    }
  }
})
</script>
