<template>
  <a-form v-bind="formItemLayout">
    <a-row>
      <a-col :span="12">
        <a-form-item label="关键字类型" name="keywordType">
          <a-input v-model:value="keywordTypeName" :disabled="true"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-form-item label="关键字扩展" name="completionStatement" :labelCol="{span: 3}">
          <a-textarea style="resize: none;" :placeholder="example"
                      allowClear :autoSize="{minRows: 8, maxRows: 15}"
                      v-model:value="keywordCode"></a-textarea>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getRuleJsConfigMethod} from '../../../utils/request_extend'

export default defineComponent({
  setup(props, {emit}) {
    const state = reactive({
      keywordTypeName: '业务对象及属性',
      keywordCode: '',
      example: '业务对象及属性添加示例:\n' +
        'contract=保单\n' +
        'contract.contNo=保单号\n' +
        'contract.valiDate=保单的印刷号\n'
    })

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
    }

    // 新增
    const handelAddList = () => {
      const arr = []
      state.keywordCode.split('\n').forEach(line => {
        if (line.length > 0) {
          let obj = line.split('=')
          if (obj.length > 1) {
            arr.push({
              keywordType: 'object',
              keywordCode: obj[0].trim(),
              keywordName: obj[1].trim(),
              describes: obj[1].trim()
            })
          }
        }
      })
      if (arr.length > 0) {
        request(getRequestURI('ruleKeyword_addList'), {data: arr}).then(res => {
          if (res.code == 1) {
            emit('handleSearch')
            message.success('保存成功')
            getRuleJsConfigMethod()
          } else {
            message.error(res.message)
          }
        })
      }
    }

    return {
      ...toRefs(state),
      handelAddList,
      formItemLayout,
    }
  }
})
</script>

<style scoped>

</style>
