<template>
  <a-drawer
    :title="isNew ? '查看系统配置' : '编辑系统配置'"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="data" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
      <a-input v-model="data.id" v-show="false"/>
      <a-form-item label="系统名称" name="systemName" :rules="[{ required: true, message: '请输入系统名称' ,trigger: 'change'}]">
        <a-input :disabled="isNew" v-model:value="data.systemName"/>
      </a-form-item>
      <a-form-item label="系统编码" name="systemCode" :rules="[{ required: true, message: '请输入系统编码' ,trigger: 'change'}]">
        <a-input :disabled="isNew" v-model:value="data.systemCode"/>
      </a-form-item>
      <a-form-item label="系统类型" name="systemType" :rules="[{ required: true, message: '请选择系统类型' ,trigger: 'change'}]">
        <a-select :disabled="isNew" v-model:value="data.systemType" :options="systemClassify">
        </a-select>
      </a-form-item>
      <a-form-item label="系统域名地址" name="envInterfaceUrl" :rules="[{ required: true, message: '请输入系统域名地址' ,trigger: 'change'}]">
        <a-input :disabled="isNew" v-model:value="data.envInterfaceUrl"/>
      </a-form-item>
      <a-form-item label="系统描述"  name="interfaceInfo">
        <a-textarea :disabled="isNew"  :auto-size="{ minRows: 2, maxRows: 10 }" v-model:value="data.interfaceInfo"/>
      </a-form-item>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" :disabled="isNew" @click="addData">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { message } from 'ant-design-vue'
import {defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import { getDictionaryData } from '../../../utils/request_extend'
export default defineComponent({
  props: {
    searchfFilters: { type: Object },
    handleSearch: {
      type: Function
    }
  },
  setup(props) {
    const data = reactive({
      id: '',
      // 系统名称
      systemName: '',
      // 系统编码
      systemCode: '',
      // 系统分类
      systemType: '',
      // 环境接口地址
      envInterfaceUrl : '',
      // 接口信息配置
      interfaceInfo : ''
    })
    const state = reactive({

      visible: false,
      isNew: true,
    })
    const showDrawer = (oldData, str) => {
      state.visible = true
      if( str === 'check'){
        state.isNew = true
      } else if(str === 'edit') {
        state.isNew = false
      }
      Object.keys(oldData).forEach((key) => {
        data[key] = oldData[key]
      })
      data.systemType += ''

    }
    // 保存按钮
    const addData = function(){
        if (data.systemType === '是') {
          data.systemType = 'Y'
        } else if (data.systemType === '否') {
          data.systemType = 'N'
          console.log(typeof(data.systemType))
        }
      console.log('修改', data)
        request(getRequestURI('env_sync_update'), {data}).then((res) => {
          if(res.code === 1){
            console.log(props)
            message.success('修改成功')
            onClose()
            props.handleSearch(props.searchfFilters)
          }else{
            message.warning(res.message)
          }
        })
    }
    // X 和 取消
    const onClose = () => {
      state.visible = false
    }
    const systemClassify = ref([])
    onBeforeMount(()=>{
      getDictionaryData(systemClassify,'system_type')
    })
    return {
      ...toRefs(state),
      showDrawer,
      onClose,
      addData,
      systemClassify,
      data,
    }
  }
})
</script>

<style scoped>

</style>
