<template>
  <search-form
    :Condition="condition"
    :ColSpan="8"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addRuleSet">
      <template #icon><FileAddOutlined /></template>新增
    </a-button>
    <a-button danger @click="delSomeRuleSet(null)">
      <template #icon><DeleteOutlined /></template>批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="tablePaginationChange"
    :loading="loading"
    :row-key="(record) => record.id"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-space>
          <a-button
            type="primary"
            @click="delSomeRuleSet(record.id)"
            size="small"
            danger
          >
            <template #icon><FormOutlined /></template>删除
          </a-button>
          <a-button type="primary" @click="editElement(record)" size="small">
            <template #icon><LinkOutlined /></template>编辑
          </a-button>
          <a-button type="primary" size="small" @click="handelRelease(record)">
            <template #icon><LinkOutlined /></template>发布
          </a-button>
          <a-button
            type="primary"
            @click="ruleDetailsElement(record)"
            size="small"
          >
            <template #icon>
              <tags-outlined /> </template
            >规则详情
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <!-- 规则详情 -->
  <rule-detail ref="detailRef" />
  <AddAndUpdate :handleIndexSearch="handleSearch" ref="ruleSetRef" :searchfFilters="searchfFilters"/>
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, computed, ref, onMounted } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request'
import { getExtendUri } from '../../../utils/request_extend'
import AddAndUpdate from './addAndUpdate.vue'
import RuleDetail from './ruleDetail.vue'
export default defineComponent({
  components: {
    SearchForm,
    AddAndUpdate,
    RuleDetail
  },
  setup () {
    const state = reactive({
      detailShow: false,
      searchNew: {},
      selectedRowKeys: [],
      condition: {
        collectionName: {
          label: '规则集名称',
        },
        id: {
          label: '规则集id',
        },
        collectionType: {
          label: '规则集类型 ',
          type: 'select',
          data: []
        },
      },
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'left',
        },
        {
          title: '规则集名称',
          width: 130,
          key: 'collectionName',
          dataIndex: 'collectionName',
        },
        {
          title: '规则集id',
          width: 100,
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '状态',
          width: 120,
          key: 'releaseStatus',
          dataIndex: 'releaseStatus',
          customRender: (text) => {
            if (text.text == 0) {
              return '未发布'
            } else if (text.text == 1) {
              return '已发布'
            }
          }
        },
        {
          title: '执行版本号',
          width: 150,
          key: 'version',
          dataIndex: 'version',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'left',
          width: 130,
          dataIndex: 'operation'
        },
      ],
    })
    // ruleCollection_list   列表查询    ruleCollection_delete  删除  ruleCollection_add  新增 / 编辑
    // ruleCollection_details 详情




    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ruleCollection_list'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})

    //查询
    const handleSearch = (filters) => {
      if (filters) {
        searchfFilters.value = filters
      }
      state.searchNew = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    // 页码变化
    const tablePaginationChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNew,
      })
    }
    // 选择
    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const ruleSetRef = ref()
    // 新增
    const addRuleSet = () => {
      ruleSetRef.value.showDrawer()
    }
    //批量删除
    const delSomeRuleSet = (id) => {
      let ids = []
      if (id) {
        ids.push(id)
      } else {
        if (!state.selectedRowKeys.length) {
          return message.warning('请选择数据')
        }
        ids = [...state.selectedRowKeys]
      }
      console.log(ids)
      Modal.confirm({
        title: '删除规则集',
        content: '是否删除已选择的规则集？',
        onOk: () => {
          request(getRequestURI('ruleCollection_delete'), { ids }).then(res => {
            if (res.code === 1) {
              handleSearch({})
              message.success('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    //编辑
    const editElement = (data) => {
      ruleSetRef.value.showDrawer(data)
    }

    // 发布
    const handelRelease = ({ id }) => {
      request(getRequestURI('ruleCollection_ruleRelease'), { id }).then(
        res => {
          if (res.code == 1) {
            message.success('发布成功'),
              handleSearch(searchfFilters.value)
          } else {
            message.warning(res.message)
          }
        }
      )
    }
    const detailRef = ref()
    // 规则详情
    const ruleDetailsElement = (record) => {
      state.detailShow = true
      detailRef.value.showDrawer(record.id)
    }
    // 获取规则集类型   还没配
    const getRuleType = () => {
      request(getExtendUri('dictionary_query_byType'), { data: { types: ['collection_type'] } }).then(
        res => {
          console.log(res)
          state.condition.collectionType.data = res.result['collection_type']
        })
    }


    const previewModal = {
      'max-height': '400px',
      'overflow': 'auto'
    }
    onMounted(() => {
      getRuleType()
    })

    return {
      ...toRefs(state),
      handleSearch,
      tablePaginationChange,
      addRuleSet,
      delSomeRuleSet,
      onSelectChange,
      editElement,
      getRuleType,
      ruleDetailsElement,
      handelRelease,
      ruleSetRef,
      previewModal,
      pagination,
      dataSource,
      loading,
      detailRef,
      searchfFilters
    }
  }
})
</script>

<style scoped>
</style>
