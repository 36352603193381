<template>
  <a-drawer
    :title="title"
    :width="'90%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <div style="margin: 10px 0 30px 50px">
    <a-form
      :model="collectionData"
      layout="vertical"
      ref="formRef"
      autocomplete="off">
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="规则集名称: " name="collectionName"  :rules="[{required:true,message:'必填信息',trigger:'blur'}]">
            <a-input v-model:value="collectionData.collectionName" placeholder="请输入规则集名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="规则集类型: " name="collectionType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select
              style="width:100%"
              v-model:value="collectionData.collectionType"
              show-search
              placeholder="请选择相应的字典"
              :options="dictionarieList"
            />
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>


  </div>
  <div class="rule_header">
    <!-- 已选规则 -->
    <div style="width: 450px">
      <div>
        <h3 style="margin-bottom:10px">
          已选规则
        </h3>
      </div>
      <!-- :loading="loading"  :data-source="dataSource ? dataSource['result'] : []" -->
      <a-table
        :columns="columns"
        :data-source="collectionData.ruleScriptList"
        :pagination="false"
        :customRow="customRow"
      >
        <template #bodyCell="{ column,index,record }">
          <template v-if="column.key === 'index'" >
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-space>
              <a-button type="primary" @click="delElement(record)" size="small" danger>
                <template #icon></template>删除
              </a-button>
            </a-space>
          </template>
        </template>
      </a-table>

    </div>
    <div style="border-left: 2px solid #666"></div>
    <div style="width: 500px">
      <a-button type="primary" @click="selectSome" style="margin-bottom:10px">
        <template #icon>
          <FileAddOutlined/>
        </template>
        批量选择
      </a-button>
      <a-table
        :columns="columns"
        :data-source="dataSource?dataSource['result'] : []"
        :pagination="pagination"
        @change="tablePaginationChange"
        :row-key="(record) => record.id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template #bodyCell="{ column,index,record }">
          <template v-if="column.key === 'index'" >
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-space>
              <a-button type="primary" @click="selectElement(record)" size="small">
                选择
              </a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>

  <template #extra>
    <a-space>
      <a-button @click="onClose">
        <template #icon>
          <CloseOutlined />
        </template>取消
      </a-button>
      <a-button @click="onReact">
        <template #icon>
          <ReloadOutlined />
        </template>重置已选
      </a-button>
      <a-button type="primary" @click="addElement">
        <template #icon>
          <SaveOutlined/>
        </template>保存
      </a-button>
    </a-space>
  </template>

  </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
import {requestUsePagination, getRequestURI,request } from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'

export default defineComponent({
  props:{
    searchfFilters: { type: Object },
    handleIndexSearch:{
      type: Function
    }
  },
  setup(props){
    const state = reactive({
      change1 : null, // 源目标数据序号
      change2 : null, // 目标数据序号
      title: '规则集新增',
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
      columns:[
        {
          key: 'index',
          title: '序号',
          width: 70,
          resizable: true,
          rowDrag: true,
          align: 'left',
        },
        {
          title: '规则名称',
          width: 130,
          rowDrag: true,
          key: 'scriptName',
          dataIndex: 'scriptName',
        },
        {
          title: '规则ID',
          width: 130,
          rowDrag: true,
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '操作',
          rowDrag: true,
          key: 'operation',
          ellipsis: true,
          align: 'left',
          width: 130,
          scopedSlots: {customRender: 'operation'}
        },
      ],

      dictionarieList:[], // 数据集字典数据
    })
    const collectionData = reactive({
      ruleScriptList:[],
      delScriptIdList: [], //编辑时 删除不是新选的数据id
      collectionName: '', // 规则集名称,
      id: null,
      collectionType: undefined, // 数据集类型
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ruleScript_originalRuleList'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //查询
    const handleSearch = () => {
      run({
        pagination: {
          current: 1,
          pageSize: 5
        },
        data: {
          collectionId: '',
          scriptName:''
        },
      })
    }
    // 页码变化
    const tablePaginationChange = (pag) =>{
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: {
          collectionId: '',
          scriptName:''
        },
      })
    }
    // 删除已选规则
    const delElement = ({id}) =>{
      if(idList.length){
        let status = idList.includes(id)
        if(status){
          collectionData.delScriptIdList.push(id)
        }
      }
      collectionData.ruleScriptList = collectionData.ruleScriptList.filter(item =>{
        return item.id !== id
      })
    }
    // table 选择
    const onSelectChange = (selectedRowKeys,selectedRows) => {
      if(collectionData.ruleScriptList.length) {
        let status = null
          for (let i = 0; i < selectedRows.length; i++) {
            status = collectionData.ruleScriptList.find(item =>{
              return selectedRows[i].id == item.originalId || selectedRows[i].id == item.id
            })
          }
          if(status){
            selectedRowKeys = []
            selectedRows = []
            return message.warning(`已选择${status.scriptName}, 不能重复选择`)
          }
      }
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    // 批量选择
    const selectSome = () =>{
      if(!state.selectedRows.length) return message.warning('请选择数据')
      state.selectedRows.forEach(item =>{
        collectionData.ruleScriptList.push(item)
      })
      state.selectedRowKeys = []
      state.selectedRows = []
    }


    const showDrawer = (data) => {
      if(data){
        state.title = '规则集编辑'
        collectionData.collectionName = data.collectionName
        collectionData.collectionType = data.collectionType
        collectionData.id = data.id
        getRuleDetail(data.id)
      }
      state.visible = true
      handleSearch()
      getDictionaryData()
    }
    // 获取规则类型字典数据
    const getDictionaryData = () =>{
      request(getExtendUri('dictionary_query_byType'),
      {data: {ids:[],types:['collection_type']}}).then(
        res =>{
          state.dictionarieList = res.result['collection_type']
      })
    }



    // 用于编辑的删除id 判断
    const idList = reactive([])

    // 获取规则集详情
    const getRuleDetail = (id) =>{
      request(getRequestURI('ruleCollection_details'),{id}).then(
        res=>{
          if(res.code === 1){
            collectionData.ruleScriptList = res.result.ruleScriptList
            let arr = []
            collectionData.ruleScriptList.forEach(item =>{
              arr.push(item.id,item.originalId)
            })
            Object.assign(idList, [...arr])
          }else {
            message.warning(res.message)
          }

        }
      )
    }



    //重置已选
    const onReact = () =>{
      collectionData.ruleScriptList = []
      state.selectedRowKeys = []
      collectionData.delScriptIdList = []
      Object.assign(idList,[])
    }
    // 保存
    const addElement = () =>{
      let arr = []
      collectionData.ruleScriptList.forEach(item=>{
        arr.push(item.id)
      })
      if(!collectionData.collectionName){
        return message.warning('请输入规则集名称')
      }
      if(!collectionData.collectionType){
        return message.warning('请输入规则集类型')
      }

      collectionData['scriptIdList'] = [...arr]
      delete collectionData.ruleScriptList
      request(getRequestURI('ruleCollection_add'),{...collectionData}).then(
        res=>{
          if(res.code ==1){
            message.success('保存成功')
            props.handleIndexSearch(props.searchfFilters)
            onClose()
          }else{
            message.warning(res.message)
          }
        })
    }
    const onClose = () =>{
      state.visible = false
      state.selectedRowKeys = []
      state.title = '规则集新增'
      Object.assign(collectionData,{
        id: null,
        ruleScriptList:[], // 已选规则
        delScriptIdList: [], //编辑时 删除不是新选的数据id
        collectionName: '', // 规则集名称,
        collectionType: undefined, // 数据集类型
      })
    }

    // 拖拽调整顺序
    const customRow = (record, index) => {
      // console.log(record, index)
      return {
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        // 鼠标移入
        onMouseenter: (event) => {
          // 兼容IE
          let  ev = event || window.event
          ev.target.draggable = true // 让你要拖动的行可以拖动，默认不可以
        },
        // 开始拖拽
        onDragstart: (event) => {
          // 兼容IE
          let  ev = event || window.event
          // 阻止冒泡
          ev.stopPropagation()
          // 得到源目标数据序号
          state.change1 = index
        },
        // 拖动元素经过的元素
        onDragover: (event) => {
          // 兼容 IE
          let ev = event || window.event
          // 阻止默认行为
          ev.preventDefault()
        },
        // 鼠标松开
        onDrop: (event) => {
          // 兼容IE
          var ev = event || window.event
          // 阻止冒泡
          ev.stopPropagation()
          // 得到目标数据序号
          state.change2 = index
          // 相等 不做处理
          if(state.change1 ==  state.change2){
            return
          }
          let temp = collectionData.ruleScriptList[state.change1]
          //拖拽下标          目标下标
          let type = state.change1 > state.change2 ? true : false
          if(type){
            for (let i = state.change1; i > state.change2; i--) {
              collectionData.ruleScriptList[i]  = collectionData.ruleScriptList[i-1]
              if(i == state.change2){
                return
              }
            }
          } else {
            console.log(type,state.change1,state.change2)
            // debugger
            for (let j = state.change1; j < state.change2; j++) {
              console.log(12312)
              collectionData.ruleScriptList[j]  = collectionData.ruleScriptList[j+1]
              if(j == state.change2){
                return
              }
            }
          }
          collectionData.ruleScriptList[state.change2] = temp

          console.log(collectionData.ruleScriptList)
        }

      }
    }

    // 选择
    const selectElement = (data) =>{
      if(collectionData.ruleScriptList.length){
        let status = collectionData.ruleScriptList.find(item =>{
          return item.id == data.id || item.originalId == data.id
        })
        if(status){
          return message.warning(`已选择${data.scriptName},请勿重复选择`)
        }
      }
      collectionData.ruleScriptList.push(data)
    }


    return {
      ...toRefs(state),
      collectionData,
      customRow,
      showDrawer,
      onReact,
      addElement,
      onClose,
      tablePaginationChange,
      onSelectChange,
      selectElement,
      handleSearch,
      delElement,
      selectSome,
      dataSource,
      loading,
      pagination,
    }
  }
})
</script>

<style  scoped>

.rule_header{
  display: flex;
  justify-content: space-around;
}
.m_center{
  width: 100%;
  height: 100%;
  margin-left:20px;
  display: flex;
  align-items: center;
  justify-items: center;
}

</style>
