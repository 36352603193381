<style scoped>
.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>

<template>
  <!--  搜索框-->
  <search-form :Condition="condition" :ColSpan="12" @callback="handleSearch">
  </search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('新增字典数据')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增数据项
    </a-button>
    <a-button type="danger" @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    className="logListTable"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 10px"
            @click="modifyUser(record,'查看字典数据')"
          >查看</a
          >&nbsp;&nbsp;
          <a
            style="color: orange; margin-right: 10px"
            @click="modifyUser(record,'修改字典数据')"
          >编辑</a
          >&nbsp;&nbsp;
          <!--          <a style="color: orange" @click="showModal(record,index)">删除</a>&nbsp;&nbsp;-->
        </div>

      </template>
    </template>
  </a-table>
  <edit-user
    ref="userDrawerRef"
    :typeData="condition.drSystemType.data"
    :typeData1="condition.drAccountType.data"
    :typeData2="condition.drSystemStatus.data"
    :typeData3="condition.supId.data"
    :getTypeData="getTypeData"
    :handleSearch="handleSearch"
    :status="status"
  ></edit-user>
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import {defineComponent, reactive, computed, ref, toRefs, onMounted, onBeforeMount} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import editUser from './addData'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import { getExtendUri} from '../../../utils/request_extend'
import {
  FileAddOutlined,
  DeleteOutlined,

} from '@ant-design/icons-vue'
// import store from '../../../store'

export default defineComponent({
  components: {
    SearchForm,//搜索组件
    FileAddOutlined,//新增图标
    DeleteOutlined,//删除图标
    editUser,//修改页面
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        drSystemType: {
          label: '双录系统类型',
          type: 'select',
          default: '1',
          data: [],
        },
        drSystemName: {
          label: '双录系统名称',
          placeholder: '请输入双录系统名称',
        },
        drAccountType: {
          label: '双录账号类型',
          type: 'select',
          default: '1',
          data: [],
        },
        drSystemStatus: {
          label: '双录系统状态',
          type: 'select',
          default: '1',
          data: [],
        },
        supId: {
          label: '保险公司',
          type: 'select',
          default: '1',
          data: [],
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 50,
          align: 'left',
        },
        {
          title: '双录系统类型',
          width: 100,
          align: 'center',
          key: 'drSystemType',
          dataIndex: 'drSystemType',
          customRender: (text)=> customRenderValue('drSystemType', text.value),
        },
        {
          title: '双录系统名称',
          width: 120,
          align: 'center',
          key: 'drSystemName',
          dataIndex: 'drSystemName',

        },
        {
          title: '保险公司名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
          // customRender: (text)=> customRenderValue('supName', text.value),
        },
        {
          title: '双录账号类型',   // 未找到对应字段
          width: 150,
          align: 'center',
          key: 'drAccountType',
          dataIndex: 'drAccountType',
          customRender: (text)=> customRenderValue('drAccountType', text.value),
        },
        {
          title: '双录系统状态',   // 未找到对应字段
          width: 150,
          align: 'center',
          key: 'drSystemStatus',
          dataIndex: 'drSystemStatus',
          customRender: (text)=> customRenderValue('drSystemStatus', text.value),
        },
        {
          title: '操作',
          key: 'operation',
          width: 100,
        },
      ],
      selectedRowKeys: [],
      newChannel: {}
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productDoubleRecordSystem_queryAll'))
    const supNameList=ref([])
    const getSupNameList=()=>{
      let uri = getRequestURI('supplier_querySupplier')
      request(uri, {}).then(res => {
        // state.dataSourceList = res.result
        supNameList.value=res.result
        supNameList.value.forEach(item=>{
          item.value=item.id
          item.label=item.supName
        })
        // console.log('11111111111',supNameList)
        state.condition.supId.data = supNameList.value
      })
    }
    onMounted(()=>{
      // getSupNameList()
    })

    onBeforeMount(()=>{
      getSupNameList()

    })


    const customRenderValue = (dictionary,type) => {
      if(!type) return
        const typeValue = state.condition[dictionary].data.find(item => item.value === type)
        // console.log(123,typeValue)
        // if(dictionary==='supName'){
        //   return typeValue.label
        // }
        return typeValue?.label


    }

    const onSelectChange = (selectedRowKeys) => {
      // console.log('selectedRowKeys changed:', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      // console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }
        // 页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      // console.log('handleSearch', filters)
      if (filters !== 'reload') {
        state.newChannel = filters
      }
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: state.newChannel,
      })
    }
    const addUser = function (f) {
      status.value=f
      userDrawerRef.value.showDrawer({},f)
    }
    const status=ref('')
    const modifyUser = function (oldData,f) {
      // status.value=status
      // console.log(1000000000000000,f)
      status.value=f
      userDrawerRef.value.showDrawer(oldData,f)
    }
    const deleteUser = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('productDoubleRecordSystem_del'), {  ids: state.selectedRowKeys }).then(res => {
            if (res.code === 1) {
              // store.dispatch('dictionary/clear')
              message.success('删除成功')
              handleSearch('reload')
              getTypeData()
              state.selectedRowKeys = []
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    // 获取数据类型
    const getTypeData = () => {
      // getDictionaryData(state.condition.drSystemType.data,'dr-system-type')
      request(getExtendUri('dictionary_query_byType'),
        { data: { ids: [], types: ['dr-system-type','dr-account-type','dr-system-status'] } }).then(
        res => {
          // console.log(3333333333333,res)
          state.condition.drSystemType.data = res.result['dr-system-type']
          // console.log(3333, state.condition.drSystemType.data)
          state.condition.drAccountType.data = res.result['dr-account-type']
          state.condition.drSystemStatus.data = res.result['dr-system-status']
        })
      // request(getExtendUri('dictionary_query_byType'),
      //   { data: { ids: [], types: ['dr-account-type'] } }).then(
      //   res => {
      //   })
      // request(getExtendUri('dictionary_query_byType'),
      //   { data: { ids: [], types: ['dr-system-status'] } }).then(
      //   res => {
      //   })
    }
    onMounted(() => {
      getTypeData()
    })

    return {
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addUser,
      modifyUser,
      deleteUser,
      getTypeData,
      userDrawerRef,
      pagination,
      loading,
      dataSource,
      getRequestURI,
      getSupNameList,
      customRenderValue,
      status
    }
  },
})
</script>
