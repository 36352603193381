<template>
<!--  <div class="sync-h">数据同步配置</div>-->
  <!-- 表单 -->
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="handelAdd">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
  </div>
  <div class="sync-table" style="margin: 0 67px">
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      @change="handleTableChange"
      :bordered="true"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a style="color: orange" @click="handelCheck(record, 'check')"
              >查看</a
            >&nbsp;&nbsp;
            <a style="color: orange" @click="handelEdit(record, 'edit')">编辑</a
            >&nbsp;&nbsp;
            <a style="color: orange" @click="handelDelData(record.id)">删除</a
            >&nbsp;&nbsp;
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <IntegratedMange ref="integrated" :handleSearch="handleSearch" />
  <CheckAndUpdate ref="checkAndUpdate" :handleSearch="handleSearch" :searchfFilters="searchfFilters"/>
</template>

<script>
import { computed, defineComponent, reactive, onMounted, toRefs, ref, onBeforeMount } from 'vue'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request'
import IntegratedMange from './integratedMange.vue'
import SearchForm from '../../../components/extended/search_form'
import CheckAndUpdate from './checkAndUpdate.vue'
import { message, Modal } from 'ant-design-vue'
import { getDictionaryValue } from '../../../utils/request_extend'
export default defineComponent({
  components: {
    IntegratedMange,
    CheckAndUpdate,
    SearchForm
  },
  setup () {
    const integrated = ref()
    const checkAndUpdate = ref()
    const state = reactive({
      // 头部搜索框
      condition: {
        systemName: {
          label: '系统名称',
          placeholder: '请输入系统名称，可根据关键字进行模糊查询'
        },
        systemCode: {
          label: '系统编码',
          placeholder: '请输入系统编码，可根据关键字进行模糊查询'
        },
        systemType: {
          label: '系统类型',
          type: 'select',
          default: '1',
          placeholder: '请选择',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'system_type' }
        }
      },
      lastFilter: {},   // 做分页  删除
      delId: '',
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '系统名称',
          width: 120,
          align: 'center',
          key: 'systemName',
          dataIndex: 'systemName'
        },
        {
          title: '系统编码',
          width: 120,
          align: 'center',
          key: 'systemCode',
          dataIndex: 'systemCode'
        },
        {
          title: '系统分类',
          width: 120,
          align: 'center',
          key: 'systemType',
          dataIndex: 'systemType',
          customRender: (text) => getDictionaryValue('system_type', text.value)

        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 180
        }
      ],
      result: []
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('env_sync_list'))
    //查看
    const handelCheck = function (record, str) {

      console.log(record)
      checkAndUpdate.value.showDrawer(record, str)
    }
    //编辑
    const handelEdit = function (oldData, str) {
      console.log(999, oldData)
      checkAndUpdate.value.showDrawer(oldData, str)
    }
    //删除
    const handelDelData = function (id) {
      console.log(id)
      Modal.confirm({
        title: '删除系统同步配置',
        content: '是否删除已选择的系统同步配置？',
        onOk: () => {
          request(getRequestURI('env_sync_delete'), { data: { id: id } }).then(res => {
            if (res.code === 1) {
              reloadList()
              message.success('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const reloadList = () => {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.lastFilter,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    // 查询
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      console.log('handleSearch', filters)
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })

    }
    // 新增
    const handelAdd = function () {
      integrated.value.showDrawer()
    }
    return {
      ...toRefs(state),
      // data,
      integrated,
      checkAndUpdate,
      pagination,
      dataSource,
      loading,
      current,
      pageSize,
      total,
      run,
      handleTableChange,
      handelCheck,
      handelEdit,
      handelDelData,
      handleSearch,
      handelAdd,
      onBeforeMount,
      searchfFilters
    }
  },

})

</script>

<style scoped>
.btn-bar {
  margin-left: 80px;
}
.sync-h {
  font-size: 16px;
  font-weight: 700;
  margin: 5px 5px 20px;
}
.sync-from {
  display: flex;
  justify-content: space-evenly;
}
.sync-buttoon {
  height: 85px;
  width: 85%;
  margin: 30px 67px 40px 67px;
  border-bottom: 1px solid rgb(183, 181, 181);
}
.sync-buttoon .butt {
  margin-left: 20px;
}
v-deep
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  margin-left: 67px !important;
}
</style>
