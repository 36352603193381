<style scoped>
.column-title {
  background: #e0e0e0;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content_scroll {
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
}
</style>

<template>
  <div class="column-title">
    <span>已授权用户{{role.id?`(${role.roleName})`:''}}</span>
    <span>
      <a-button shape="circle" @click="showUserList" :loading="loading">
        <template #icon> <PlusOutlined/> </template>
      </a-button>  <a-button shape="circle"  @click="delConfirm" danger type="primary"
                             :loading="loading">
        <template #icon> <DeleteOutlined/> </template>
      </a-button>
    </span>
  </div>
  <div class="content_scroll">
    <a-table bordered
      :columns="columns"
      :data-source="dataSource ? dataSource['result']['list'] : []"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange
      }"
      :row-key="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading2"
    >
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {request, getRequestURI, requestUsePagination} from '../../../utils/request'

export default defineComponent({
  components: {},
  emits: ['showUserSelect', 'show-user-select'],
  setup(props, context) {
    const state = reactive({
      loading: false,
      selectUsers: [],
      role: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 70,
          align: 'center'
        },
        {
          title: '姓名',
          align: 'left',
          dataIndex: 'realName'
        }
      ],
      selectedRowKeys: []
    })
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }
    const showUserList = () => {
      if (state.role && state.role.id) {
        context.emit('showUserSelect', state.role)
      } else {
        Modal['warning']({
          title: '添加用户',
          content: '请先选择角色'
        })
      }
    }
    const delConfirm = () => {
      if (!state.selectedRowKeys.length) return message.warning('请选择数据')
      state.selectUsers = state.selectedRowKeys
      if (state.selectUsers.length === 0) {
        message['error']('请选择需要禁用此角色的用户')
      } else {
        Modal.confirm({
          title: '删除用户角色',
          content: '是否确认删除',
          onOk: () => {
            delUserRole()
          },
          onCancel: () => {
            Modal.destroyAll()
          }
        })
      }
    }
    const delUserRole = () => {
      if (state.role && state.role.id) {
        request(getRequestURI('role_deleteUserRole'), {data:{
            roleId: state.role.id,
            userIds: state.selectUsers
          }}).then(result => {
          if (result.code === 1) {
            state.selectUsers = []
            selectList()
            message['success']('删除成功')
          } else {
            message['error'](result.message)
          }
        })
      }
    }
    const {
      data: dataSource,
      run,
      loading: loading2,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('role_queryUserRole'))


    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
    })
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: {
          roleId: state.role.id
        }
      })
      Object.keys(pagination).forEach(item =>{
        pagination[item] = pag[item]
      })
    }
    const selectList = (_data) => {
      if (_data) {
        state.role = _data
      }
      request(getRequestURI('role_queryUserRole'),
      {pagination: pagination,
        data: {
          roleId: state.role.id
        },}
      ).then(res =>{
        dataSource.value = res
        pagination.total = res.result.total
      })


    }

    context.expose({
      selectList
    })
    return {
      ...toRefs(state),
      showUserList,
      delConfirm,
      dataSource,
      loading2,
      handleTableChange,
      onSelectChange,
      pagination,
      current,
      pageSize,
      total,
    }
  }
})
</script>
