<style scoped>
</style>

<template>
  <a-drawer
    title="查找用户"
    :visible="showSelectUser"
    width="60%"
    @close="close"
  >
    <search-form
      :Condition="condition"
      :ColSpan="12"
      ref="searchRef"
      @callback="handleSearch"
    ></search-form>
    <a-table bordered
             :columns="columns"
             :data-source="dataSource ? dataSource['result']['list'] : []"
             :row-selection="{
        selectedRowKeys: userSelected,
        onChange: onSelectChange
      }"
             :row-key="(record) => record.id"
             :pagination="pagination"
             @change="handleTableChange"
             :loading="loading"
    >
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>
    <template #extra>
      <a-button type="primary" @click="saveSelectUser"> <SaveOutlined/>选择</a-button>
    </template>
  </a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs, ref} from 'vue'
import {message} from 'ant-design-vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'

export default defineComponent({
  components: {
    SearchForm
  },
  setup(props, context) {
    const state = reactive({
      condition: {
        realName: '姓名',
        email: '电子邮箱',
        phoneNo: '手机号'
      },
      showSelectUser: false,
      role: {},
      // table
      loading: false,
      userSelected: [],
      columns: [
        {
          title: '序号',
          width: 70,
          align: 'center',
          key: 'index'
        },
        {
          title: '姓名',
          width: 140,
          align: 'center',
          dataIndex: 'realName'
        },

        {
          title: '手机号',
          width: 140,
          align: 'center',
          dataIndex: 'phoneNo'
        },
        {
          title: '电子邮箱',
          width: 230,
          align: 'center',
          dataIndex: 'email'
        },
        {
          title: '所属部门',
          width: 160,
          align: 'center',
          dataIndex: 'departmentName'
        }
      ]
    })
    const onSelectChange = (selectedRowKeys) => {
      state.userSelected = selectedRowKeys
    }
    const handleSearch = (_data) => {
      run({
        pagination:pagination,
        data: _data,
      }).then(res =>{
        pagination.total = res.result.total
      })
    }
    const {
      data: dataSource,
      run,
      loading,

    } = requestUsePagination(getRequestURI('role_userQuery'))
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: [10, 20],
    })
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: filters,
      })
      Object.keys(pagination).forEach(item =>{
        pagination[item] = pag[item]
      })
    }
    const searchRef = ref()
    const close = () => {
      searchRef.value.deleteAll()
      state.userSelected = []
      state.showSelectUser = false
    }
    const saveSelectUser = () => {
      if (state.userSelected.length > 0) {
        request(getRequestURI('role_saveUserRole'), {
          data: {
            roleId: state.role.id,
            userIds: state.userSelected
          }
        }).then(result => {
          if (result.code === 1) {
            close()
            context.emit('callback')
            message['success']('添加成功')
          } else {
            message['error'](result.message)
          }
        })
      } else {
        state.showSelectUser = false
      }
    }
    const showUser = (activeRole) => {
      state.role = activeRole
      state.showSelectUser = true
      handleSearch({})
    }
    context.expose({
      showUser
    })
    return {
      ...toRefs(state),
      handleSearch,
      close,
      saveSelectUser,
      onSelectChange,
      pagination,
      handleTableChange,
      dataSource,
      loading,
      searchRef
    }
  }
})
</script>
