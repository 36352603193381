<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew?'新建用户':'修改用户信息'"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-form-item label="编码" name="id" v-show="false" >
        <a-input v-model:value="formData.id" />
      </a-form-item>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="姓名" name="realName" required>
            <a-input v-model:value="formData.realName" placeholder="请输入姓名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--  -->
          <a-form-item label="手机号" name="phoneNo" required
            :rules="[{pattern:/^((13[0-9])|(14[0-9])|(15[0-9])|(17[0-9])|(18[0-9]))\d{8}$/, message: '请输入正确格式的手机号' ,trigger: 'change'}]"
          >
            <a-input
              v-model:value="formData.phoneNo"
              placeholder="请输入手机号"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="账号" name="employeeNo"
            required
            :rules="[{pattern:/^[0-9a-zA-Z]+$/, message: '支持数字、字母格式' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData.employeeNo" placeholder="请输入账号"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="所属部门" name="departmentName">
            <a-input
              v-model:value="formData.departmentName"
              placeholder="请输入所属部门"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="电子邮箱" name="email" required>
            <a-input v-model:value="formData.email" placeholder="请输入电子邮箱"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="状态" name="userStatus" required>
            <a-select v-model:value="formData.userStatus" placeholder="请选择状态">
              <a-select-option :value="1">启用</a-select-option>
              <a-select-option :value="2">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="启用日期" name="startDate" required>
            <a-date-picker
              mode="date"
              placeholder="请输入启用日期"
              v-model:value="formData.startDate"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ format: 'HH:mm:ss' }"
              @ok="handelStartDate"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="过期日期" name="endDate" required>
            <a-date-picker
              mode="date"
              placeholder="请输入过期日期"
              v-model:value="formData.endDate"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ format: 'HH:mm:ss' }"
              :disabledDate="handelDisableMax"
              @ok="handelMinDate"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="用户角色" name="roleIds" required>
            <a-transfer
              :data-source="roleData"
              v-model:target-keys="formData.roleIds"
              :titles="[' 未选角色', ' 已选角色']"
              :one-way="true"
              :render="item => item.title"
              :locale="{ itemUnit: '项', itemsUnit: '项', notFoundContent: '列表为空', searchPlaceholder: '请输入搜索内容' }	"
              :list-style="{width: '50%', height: '240px'}"
            ></a-transfer>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <ReloadOutlined/>重置
        </a-button>
        <a-button type="primary" @click="onSave" v-if="getRequestURI('user_post')||getRequestURI('user_put')">
          <SaveOutlined/>提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {ReloadOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {now} from '../../../utils/date_util'
import {message} from 'ant-design-vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    ReloadOutlined, SaveOutlined
  },

  setup(props, {expose, emit}) {

    const formRef = ref()
    const formRules = {
      realName: [
        {required: true,}
      ],
      phoneNo: [
        {required: true,}
      ],
      email: [
        {required: true, type: 'email'}
      ],
      userStatus: [
        {
          required: true,
          type: 'number'
        }
      ],
      startDate: [
        {
          required: true,
          type: 'date'
        }
      ]
    }
    const state = reactive({
      visible: false,
      isNew: true,
      roleData: [],
      formData: {
        id: '',
        realName: '',
        phoneNo: '',
        employeeNo: '',
        departmentName: '',
        email: '',
        userStatus: 1,
        startDate: now(),
        endDate: '',
        roleIds: []
      }
    })

    const showDrawer = (oldData) => {
      state.formData = {
        id: '',
        realName: '',
        phoneNo: '',
        employeeNo: '',
        departmentName: '',
        email: '',
        userStatus: 1,
        startDate: now(),
        endDate: '',
        roleIds: []
      }
      state.isNew = !(oldData && typeof oldData.id === 'string' && oldData.id.length > 0)
      if (oldData) {
        Object.keys(oldData).forEach(key => state.formData[key] = oldData[key])
      }
      selectAllRoles()
      state.visible = true
    }
    const selectAllRoles = () => {
      request(getRequestURI('user_queryRole'), {data: {roleId:state.formData.id}}).then((res) => {
        if (res.code === 1) {
          state.roleData = res.result.map((item) => {
            return {
              key: item.id,
              title: item.roleName,
              description: item.remark,
              disabled: item.roleStatus === 2
            }
          })
          state.formData.roleIds = res.result.filter(item => item.roleStatus === 1).map(item => item.id)
        }
      })
    }
    const onClose = () => {
      state.visible = false
      formRef.value.resetFields()
    }
    const onFormClean = () => {
      formRef.value.resetFields()
      state.formData = {
        id: '',
        realName: '',
        phoneNo: '',
        employeeNo: '',
        departmentName: '',
        email: '',
        userStatus: 1,
        startDate: now(),
        endDate: '',
        roleIds: []
      }
    }
    const onSave = async () => {
      try {
        const values = await formRef.value.validate()
        let url = getRequestURI('user_put')
        if (state.isNew) {
          url = getRequestURI('user_post')
        }
        request(url, {
          data: {
            user: values,
            roleIds: state.formData.roleIds
          }
        }).then(result => {
          if (result.code === 1) {
            emit('callback')
            message['success']('保存成功')
            state.visible = false
          } else {
            message['error'](result.message)
          }
        })
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    }
    const handelStartDate = () =>{
      state.formData.endDate = ''
    }
    // 限制过期时间
    const handelDisableMax = date =>{
      return date && date < dayjs(state.formData.startDate)
    }
    const handelMinDate = (date) =>{
      if(new Date((state.formData.startDate)).getTime() > new Date((date)).getTime()){
        state.formData.endDate = ''
        return message.warning('过期日期不能早于启用日期,请重新选择')
      }
    }

    expose({
      showDrawer
    })
    return {
      handelMinDate,
      handelDisableMax,
      handelStartDate,
      formRef,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI
    }
  },

})
</script>
