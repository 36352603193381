<style scoped>
.ant-layout {
  background: #FFFFFF;
  border: 1px solid #e0e0e0;
}

.ant-layout-sider, .ant-layout-content {
  overflow: auto;
  height: 100%;
}

.layout-split {
  display: inline-block;
  height: 100%;
  background: #e0e0e0;
  width: 5px;
  cursor: w-resize;
  z-index: 999;
  position: absolute;
}
</style>

<template>
  <a-layout style="height: 100%;position: relative;" ref="layoutRef">
    <a-layout-sider theme="light" :width="width[0]">
      <role-list ref="roleListRef" @getRoleId="changeMenuAndUser" @editRole="editRole"></role-list>
    </a-layout-sider>
    <div class="layout-split" :style="{left: width[0] + 'px'}" @mousedown="(event) => startDrag(event, 0, this)"></div>
    <a-layout-sider theme="light" :width="width[1]" :style="{'margin-left': '5px'}">
      <menu-tree ref="menuTreeRef"></menu-tree>
    </a-layout-sider>
    <div class="layout-split" :style="{left: ( width[0] + width[1] + 5)+ 'px'}"
         @mousedown="(event) => startDrag(event, 1, this)"></div>
    <a-layout-content :style="{'margin-left': '5px'}">
      <user-list ref="userListRef" @showUserSelect="openUserSelect"></user-list>
    </a-layout-content>
  </a-layout>
  <edit-role ref="editRoleRef" @callback="reloadRoleList"></edit-role>
  <select-user ref="selectUserRef" @callback="reloadUserRoleList"></select-user>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import RoleList from './role_list'
import MenuTree from './menu_tree'
import UserList from './user_list'
import EditRole from './edit_role'
import SelectUser from './select_user'

export default defineComponent({
  components: {RoleList, MenuTree, UserList, EditRole, SelectUser},
  setup() {
    const layoutRef = ref()
    const roleListRef = ref()
    const menuTreeRef = ref()
    const userListRef = ref()
    const editRoleRef = ref()
    const selectUserRef = ref()
    const state = reactive({
      isDrag: false,
      width: [300, 300],
      minWidth: 50,
      dragTarget: 0,
      dragX: 0,
      originalX: 0
    })
    const startDrag = (event, target) => {
      if (event.button === 0) {
        state.dragTarget = target
        state.dragX = event.clientX
        state.originalX = state.width[target]
        state.isDrag = true
      }
    }

    const drag = (event) => {
      if (event.button === 0 && state.isDrag === true) {
        event.preventDefault()
        let newWidth = state.originalX + event.clientX - state.dragX
        const maxWidth = layoutRef.value.$el.clientWidth - 10 - state.minWidth - state.width[1 - state.dragTarget]
        state.width[state.dragTarget] = newWidth < state.minWidth ? state.minWidth : (newWidth > maxWidth ? maxWidth : newWidth)
      }
    }
    const stopDrag = () => {
      state.isDrag = false
    }
    onMounted(() => {
      layoutRef.value.$el.addEventListener('mouseup', stopDrag)
      layoutRef.value.$el.addEventListener('mousemove', drag)
    })
    // --- role ---
    const reloadRoleList = () => {
      roleListRef.value.roleInit()
    }
    const changeMenuAndUser = (activeRole) => {
      menuTreeRef.value.roleMenu(activeRole)
      userListRef.value.selectList(activeRole)
    }
    const editRole = (activeRole) => {
      editRoleRef.value.showRole(activeRole)
    }
    const openUserSelect = (activeRole) => {
      selectUserRef.value.showUser(activeRole)
    }
    const reloadUserRoleList = () => {
      userListRef.value.selectList()
    }
    return {
      ...toRefs(state),
      startDrag,
      drag,
      stopDrag,
      layoutRef,
      changeMenuAndUser,
      editRole,
      openUserSelect,
      reloadRoleList,
      reloadUserRoleList,
      roleListRef,
      menuTreeRef,
      userListRef,
      editRoleRef,
      selectUserRef
    }
  }
})
</script>
