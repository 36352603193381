<style scoped>
:deep(.ant-switch-handle){
  width: 16px;
  height: 16px;
}
</style>

<template>
  <div class="monitor-content">
    <a-list item-layout="vertical" :bordered="true">
<!--      <template v-for="(item, index) in dtt" :key="item['taskId'] + '_' + index">-->
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              <label style="margin-left: 20px;">产品发布开关</label>&nbsp;
              <a-switch style="width: 40px;height: 20px;" v-model:checked="isChecked" checked-value="ON" un-checked-value="OFF" @change="changeStatus"/>
            </template>
          </a-list-item-meta>
        </a-list-item>
<!--      </template>-->
    </a-list>
    <a-modal width="700px" v-model:visible="selectTask" @cancel="onLabelCancel" @ok="onLabelSave"
             :closable="false" :mask-closable="false"
             :title="currentTask.taskName">
      <p>111111111111</p>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {request} from '@/utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup () {
    const state = reactive({
      selectTask: false,
      isChecked: 'OFF',
      currentTask: {}
    })
    onMounted(()=>{
      getStatus()
    })
    const changeStatus = () =>{
      request('/product-service/product/productSwitch/api/update?switchValue='+state.isChecked, {},true,'get')
    }
    const  getStatus = () =>{
      request('/product-service/product/productSwitch/api/getSwitchStatus',{},true,'get').then(res =>{
        if(res.code === 1){
          state.isChecked = res.result
        }else{
          message.warning(res.message)
        }
      })
    }


    const onLabelCancel = () =>{}
    const onLabelSave = () =>{}

    return {
      ...toRefs(state),
      onLabelCancel,
      onLabelSave,
      changeStatus
    }
  },
})
</script>
