<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button  type="primary" @click="addUsers('newAdd')">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined/>
      </template>
      删除
    </a-button>
    <a-button danger @click="serveProduct()">
      <template #icon>
        <FileAddOutlined/>
      </template>
      产品捆绑配置
    </a-button>
  </div>
  <a-table
    id="table"
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a-button type="text" style="margin-right: 10px; color: orange;" size="small"
                    @click="selUser(record,'details')">查看
          </a-button>
          <a-button type="text" style="margin-right: 10px; color: orange;" size="small" @click="upUser(record,'edit')">
            编辑
          </a-button>
<!--          <a-button type="text" style="margin-right: 10px; color: orange;" size="small"-->
<!--                    @click="deleteOne(record,index)">删除-->
<!--          </a-button>-->

        </div>
      </template>
    </template>
  </a-table>
  <editAgent   ref="userDrawerRef" :status="status" :handleMainSearch="handleSearch"></editAgent>
  <configuration ref="configurationRef"  ></configuration>

</template>
<script>
import {computed, defineComponent, reactive, ref, toRefs} from 'vue'
import editAgent from './editServe'
import configuration from './productConfiguration'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {Modal, message} from 'ant-design-vue'
import {
  FileAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    editAgent, SearchForm,configuration,FileAddOutlined,
    DeleteOutlined,
  },
  setup() {
    const userDrawerRef = ref()
    const configurationRef =ref()
    const state = reactive({
      del: '',
      deleteQuery: {},
      dataSource: [],
      condition: {
        serviceProductName: '服务产品名称',
        serviceProductCode: '服务产品编码',
        serviceProductType: {
          label: '服务产品分类',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'otherProductClass'}
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '服务产品名称',
          dataIndex: 'serviceProductName',
          key: 'serviceProductName',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '服务产品编码',
          dataIndex: 'serviceProductCode',
          align: 'center',
          key: 'serviceProductCode'
        },
        {
          title: '服务产品分类',
          dataIndex: 'serviceProductType',
          align: 'center',
          key: 'serviceProductType',
          customRender: (text) => {
            if (text.text === 1) {
              return '实物'
            } else if (text.text === 2) {
              return '虚拟'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 280,
          scopedSlots: {customRender: 'operation'}
        }
      ],
      status: '',
      selectedRowKeys: [],
      query: {
        data: {
          id: 0,

        }
      },
      data:[],
      result: [],
      searchNow: {}//分页
    })

    //分页查询
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('serviceProductInfo'))
    //查询
    const handleSearch = function (filters) {
      console.log('服务产品查询参数==', filters)
      state.searchNow = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })


    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    //复选
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    //批量删除
    const delMultiple = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          // visible.value = true
          request(getRequestURI('deleteById'), {
            data: {
              ids: keys
            }
          }, true, 'post').then(
            (result) => {
              console.log('批量删除的结果:', result)
              state.selectedRowKeys = []
              handleSearch({})
              dataSource.value = result.result

            }
          )
        },
        onCancel() {
        }
      })
    }

    //新增
    const addUsers = function (status) {
      console.log(status)
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //产品配置
    const serveProduct= function (){

      configurationRef.value.showDrawer()
    }

    //编辑
    const upUser = function (record, status) {
      state.status = status
      const uri = getRequestURI('service-productInfo')
      request(uri, {data: record}).then(res => {
        userDrawerRef.value.showDrawer(res.result, status)
      })
    }
    //查看
    const selUser = function (record, status) {
      console.log(record)
      state.status = status
      const uri = getRequestURI('service-productInfo')
      request(uri, {data: record}).then(res => {
        userDrawerRef.value.showDrawer(res.result, status)
      })
    }

    return {
      userDrawerRef,
      configurationRef,
      addUsers,
      upUser,
      selUser,
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      // deleteOne,
      serveProduct,
      loading,
      dataSource,
      pagination,
      delMultiple
    }
  },
})
</script>
<style scoped>

</style>
