<template>
  <search-form
    :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
  <div class="btn-bar">
    <a-button danger @click="delElement">
      <template #icon >
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>
    <a-button type="primary" @click="editExcelData('save')">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-key="(record) => record.id"
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <!--    :expandIcon="expandIcon"-->
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="editExcelData('edit',record)"
          >编辑
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
  <excel-save-edit ref="excelDrawerRef" :handleSearch="handleSearch" :select-data="lastFilter" :current="pagination.current"></excel-save-edit>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'
import ExcelSaveEdit from './excelSaveEdit'

export default defineComponent({
  components: {
    SearchForm,
    ExcelSaveEdit
  },
  setup() {
    const excelDrawerRef = ref()
    const state = reactive({
      lastFilter:{},
      selectedRowKeys: [],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 80,
          fixed: 'left',
          align: 'center',
        },
        {
          title: 'Excel表头信息',
          width: 150,
          align: 'center',
          key: 'tableTitle',
          dataIndex: 'tableTitle',
        },
        {
          title: '映射值信息',
          width: 230,
          key: 'mappingValue',
          align: 'center',
          dataIndex: 'mappingValue',
        },
        {
          title: '描述',
          width: 200,
          key: 'formatDescription',
          align: 'center',
          dataIndex: 'formatDescription',
        },
        {
          title: '创建时间',
          width: 160,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 180,
        },
      ],
      condition: {
        tableTitle: 'Excel表头信息',
        mappingValue: {
          label: '映射值信息',
          placeholder: '请输入映射值信息（被保人对象属性）',
        },
      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('excel_selectList'))
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const editExcelData = function (type,value) {
      excelDrawerRef.value.showDrawer(type, value)
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num) {
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const delElement = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('excel_deleteBatch'), { ids: state.selectedRowKeys }).then(res => {
            if (res.code === 1) {
              handleSearch(state.lastFilter)
              state.selectedRowKeys = []
              message.success('删除成功')
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    return {
      ...toRefs(state),
      handleSearch,
      handleTableChange,
      run,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      onSelectChange,
      pagination,
      editExcelData,
      excelDrawerRef,
      delElement,
    }
  }
})
</script>

<style scoped>
.btn-bar {
  padding-bottom: 10px;
}
.btn-bar button {
  margin-right: 10px;
}
</style>
