<template>
<a-drawer
    title="规则详情"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
    >
      <template #bodyCell="{ column,index }">
        <template v-if="column.key === 'index'">
          {{ index + 1 }}
        </template>
      </template>
    </a-table>
</a-drawer>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { getRequestURI, request } from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup(){
    const state = reactive({
      visible: false,
      columns:[
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'left',
        },
        {
          title: '规则名称',
          width: 130,
          key: 'scriptName',
          dataIndex: 'scriptName',
        },
        {
          title: '规则id',
          width: 100,
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '脚本内容',
          width: 130,
          key: 'scriptText',
          dataIndex: 'scriptText',
        },
        {
          title: '所属规则集',
          width: 150,
          key: 'collectionName',
          dataIndex: 'collectionName',
        },
      ],
      dataSource:[],
      pagination : {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    })

    const showDrawer = (id) => {
      state.visible = true
      getRuleDetail(id)
    }
    // 获取规则集详情
    const getRuleDetail = (id) =>{
      request(getRequestURI('ruleCollection_details'),{id}).then(
        res=>{
          if (res.code === 1){
            if(res.result && res.result.ruleScriptList && res.result.ruleScriptList.length){
              res.result.ruleScriptList.forEach(item => {
                item.collectionName = res.result.collectionName
              })
              state.dataSource = res.result.ruleScriptList
            }
          }else {
            message.warning(res.message)
          }

        }
      )
    }
    const onClose = () =>{
      state.visible = false
      state.dataSource = []
    }
    return {
      ...toRefs(state),
      getRuleDetail,
      showDrawer,
      onClose,
    }
  }
})
</script>

<style scoped>

</style>
