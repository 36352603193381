<style scoped></style>

<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser" v-if="getRequestURI('user_post')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="restUserPassword">
      <template #icon>
      </template>
      重置密码
    </a-button>
    <a-button danger @click="deleteUser" v-if="getRequestURI('user_delete')">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 1500 }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="primary"
          @click="modifyUser(record)"
          size="small"
          v-if="getRequestURI('user_put')"
        >
          <template #icon>
            <FormOutlined />
          </template>
          修改
        </a-button>
      </template>
    </template>
  </a-table>
  <edit-user ref="userDrawerRef" @callback="reloadList"></edit-user>
</template>

<script>
import { defineComponent, reactive, onMounted, computed, ref, toRefs } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import editUser from './editUser'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import {
  FileAddOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'

export default defineComponent({
  components: {
    FileAddOutlined,
    DeleteOutlined,
    FormOutlined,
    SearchForm,
    editUser,
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        realName: {
          label: '姓名',
          placeholder: '请输入姓名，可根据关键字进行模糊查询',
        },
        phoneNo: '手机号',
        employeeNo: '账号',
        startDate: {
          label: '创建时间',
          type: 'date-picker',
        },
        userStatus: {
          label: '状态',
          type: 'select',
          default: '1',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'label_status' }
        },
      },
      lastFilter: {},
      columns: [
        {
          key: 'index',
          title: '序号',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '姓名',
          width: 140,
          fixed: 'left',
          key: 'realName',
          dataIndex: 'realName',
        },
        {
          title: '账号',
          width: 120,
          align: 'center',
          key: 'employeeNo',
          dataIndex: 'employeeNo',
        },
        {
          title: '手机号',
          width: 140,
          align: 'center',
          key: 'phoneNo',
          dataIndex: 'phoneNo',
        },
        {
          title: '电子邮箱',
          width: 250,
          align: 'center',
          key: 'email',
          dataIndex: 'email',
        },
        {
          title: '所属部门',
          width: 160,
          align: 'center',
          key: 'departmentName',
          dataIndex: 'departmentName',
        },
        {
          title: '状态',
          width: 100,
          align: 'center',
          key: 'statusName',
          dataIndex: 'statusName',
        },
        {
          title: '启用日期',
          width: 120,
          align: 'center',
          key: 'startDate',
          dataIndex: 'startDate',
        },
        {
          title: '过期日期',
          width: 120,
          align: 'center',
          key: 'endDate',
          dataIndex: 'endDate',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 80,
        },
      ],
      selectedRowKeys: [],
      selectedRowList: [],
      searchNow: {},
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(
      getRequestURI('user_list'),
      {
        pagination: {
          current: 1,
          pageSize: 10
        }
      })
    const onSelectChange = (selectedRowKeys, list) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.selectedRowList = list
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
      console.log('dataSource', dataSource, total.value, pageSize.value)
    }
    const reloadList = () => {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.lastFilter,
      })
    }
    const addUser = function () {
      userDrawerRef.value.showDrawer()
    }
    const modifyUser = function (oldData) {
      userDrawerRef.value.showDrawer(oldData)
    }
    const deleteUser = function () {
      if (!state.selectedRowKeys.length) {
        return message.warning('请选择用户')
      }
      Modal.confirm({
        title: '删除用户',
        content: '是否删除已选择用户？',
        onOk: () => {
          request(getRequestURI('user_delete'), { data: { ids: state.selectedRowKeys } }).then(res => {
            if (res.code === 1) {
              reloadList()
              state.selectedRowKeys = []
              message['success']('删除成功')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const userRef = ref()
    const restUserPassword = function () {
      if (!state.selectedRowKeys.length) {
        return message.warning('请选择数据')
      }
      let arr = []
      state.selectedRowList.forEach(item => {
        arr.push(item.email)
      })
      request(getRequestURI('user_sendForgotPwdEmail'), { data: { emailList: arr } }).then(
        res => {
          console.log(res)
          if (res.code == 1) {
            state.selectedRowKeys = []
            state.selectedRowList = []
            message.success('重置成功, 请在邮箱查收新密码')
          } else {
            message.warning(res.message)
          }
        }
      )

    }

    return {
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addUser,
      modifyUser,
      deleteUser,
      restUserPassword,
      userDrawerRef,
      userRef,
      pagination,
      loading,
      dataSource,
      getRequestURI,
      reloadList
    }
  },
})
</script>
