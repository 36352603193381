<style scoped>
</style>

<template>
  <a-drawer
    title="服务产品捆绑保险产品配置"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-tabs v-model:activeKey="activeKey" @change="(key) => { activeKey= key }">
      <a-tab-pane key="1" tab="服务产品捆绑保险产品配置">
        <serve-agent ref="serveDrawerRef"></serve-agent>
      </a-tab-pane>
      <a-tab-pane key="2" tab="保险产品捆绑服务产品配置" >
        <agent-serve ref="agentDrawerRef"></agent-serve>
      </a-tab-pane>
    </a-tabs>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {defineComponent, reactive, toRefs, ref, } from 'vue'
import serveAgent from './serveAgent'
import agentServe from './agentServe'

export default defineComponent({
  components: {
    serveAgent,
    agentServe
  },

  setup(props, {expose}) {
    const serveDrawerRef = ref()
    const agentDrawerRef = ref()
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})

    const state = reactive({
      look:false,
      visible: false,
      columnsOne: [
        {
          title: '序号',
          width: 60,
          align: 'left',
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '服务产品名称',
          width: 200,
          align: 'center',
          key: 'serviceProductName',
          dataIndex: 'serviceProductName',
        },
        {
          title: '服务产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '是否单独销售',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },

      ],
      columnsTwo: [
        {
          title: '序号',
          width: 60,
          align: 'left',
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '服务产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '服务产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '是否单独销售',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },


      ],
      conditionOne: {
        systemCode: {
          label: '服务产品名称',
          type: 'input',
          default: '',
          data: []
        },
        supplierId: {
          label: '服务产品编码',
          type: 'input',
          default: '',
          data: []
        },
        productId: {
          label: '是否单独销售',
          type: 'select',
          default: '',
          data: [
            {value: '1', label: '是'},
            {value: '2', label: '否'},
          ]
        },
      },
      conditionTwo: {
        systemCode: {
          label: '供应商名称',
          type: 'input',
          default: '',
          data: []
        },
        supplierId: {
          label: '产品名称',
          type: 'input',
          default: '',
          data: []
        },
        productId: {
          label: '产品编码',
          type: 'select',
          default: '',
          data: []
        },
      },
      selectedRowKeys: [],
      // dataSource: [],
      searchNow: {},//分页
      data: [],
      result: [],
    })

    const serveNameShow = ref(false)

    const activeKey = ref('1')


    //抽屉
    const showDrawer = (oldData) => {
      state.look= true
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      activeKey.value = '1'
    }

    //关闭 清空
    const onClose = () => {
      state.visible = false
      state.look = false
      state.dataSource = []
      agentDrawerRef.value.onClose()
      serveDrawerRef.value.onClose()
      // formRef.value.clearValidate(),
      // formRef.value.resetFields(),
    }


    expose({
      showDrawer,
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      activeKey,
      serveNameShow,
      checked: ref(false),
      isCheckInfo,
      serveDrawerRef,
      agentDrawerRef,
    }
  },
})
</script>
