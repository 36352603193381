<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <a-button @click="addProduct(serviceName)" style="margin: 10px" >
    添加
  </a-button>
  <a-button danger @click="delMultiple" >
    删除
  </a-button>
  <a-table
    class="bg"
    :key="time"
    :columns="columns"
    :data-source="dataSourceList"
    :scroll="{ x: 1500 }"
    :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
			type: 'radio',
		}"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
    </template>
  </a-table>

  <edit-agent  ref="agentDrawerRef"></edit-agent>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
// import {getRequestURI, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message, Modal} from 'ant-design-vue'
import editAgent from './editAgentServe'


export default defineComponent({
  components: {
    // ReloadOutlined,
    // SaveOutlined,
    SearchForm,
    editAgent
  },

  setup(props, {expose}) {
    const serveDrawerRef = ref()
    const agentDrawerRef = ref()
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})

    const state = reactive({
      visible: false,
      time: null,
      dataSourceList: [],
      columns: [
        {
          title: '序号',
          width: 60,
          align: 'left',
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '服务产品名称',
          width: 200,
          align: 'center',
          key: 'serviceProductName',
          dataIndex: 'serviceProductName',
        },
        {
          title: '服务产品编码',
          width: 100,
          align: 'center',
          key: 'serviceProductCode',
          dataIndex: 'serviceProductCode',
        },
        {
          title: '是否单独销售',
          width: 100,
          align: 'center',
          key: 'isOnly',
          dataIndex: 'isOnly',
        },


      ],
      condition: {
        supName: {
          label: '供应商名称',
          type: 'input',
          default: '',
          data: []
        },
        productName: {
          label: '产品名称',
          type: 'input',
          default: '',
          data: []
        },
        productCode: {
          label: '产品编码',
          type: 'input',
          default: '',
          data: []
        },
      },
      selectedRowKeys: [],
      // dataSource: [],
      searchNow: {},//分页
      data: [],
      result: [],
    })

    const serveNameShow = ref(false)
    // const activeKey = ref('1')

    //分页查询1
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(
      getRequestURI('select-ProductRelation')
    )



//查询
//    select-ProductRelation  保险绑定服务
//    selectServiceProductRelation 服务绑定保险
    const handleSearch = function (filters) {
      console.log('服务绑定保险查询参数==', filters)
      state.searchNow = filters
      run({
        pagination: {
          current: 1,
          pageSize:10
        },
        data: filters,
      }).then(res => {
        state.dataSourceList = res.result
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },

        sorter,
        data: state.searchNow,
      }).then(res => {
        state.dataSourceList = res.result
      })
    }
    const onSelectChange = (selectedRowKeys,selectedRowrode) => {
      console.log('selectedRowKeys复选的 changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys

      selectedRowrode.forEach(item => {
        state.serviceName = item.productInfoId
      })
      console.log(selectedRowrode,'selectedRowrode')
    }


    //抽屉
    const showDrawer = (oldData) => {
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }

    //添加再弹抽屉2
    const addProduct = () =>{
      agentDrawerRef.value.showDrawer({}, state.serviceName)
    }


    //关闭 清空
    const onClose = () => {
      state.visible = false
      state.dataSourceList = []
      pagination.value.total = 0
      state.time = new Date().getTime()
      // formRef.value.clearValidate(),
      // formRef.value.resetFields(),
    }
    // 重置
    const onFormClean = () => {

      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
// 批量删除
    const delMultiple = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          request(getRequestURI('deleteServiceProductRelation'), {
            data: {
              ids: keys
            }
          }, true, 'post').then(
            (result) => {
              console.log('批量删除的结果:', result)
              state.selectedRowKeys = []
              handleSearch({})
              dataSource.value = result.result

            }
          )
        },
        onCancel() {
        }
      })
    }




    expose({
      showDrawer,
      onClose
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      // onSave,
      onFormClean,

      loading,
      dataSource,
      handleSearch,
      onSelectChange,
      handleTableChange,
      pagination,
      serveNameShow,
      checked: ref(false),
      isCheckInfo,
      addProduct,
      serveDrawerRef,
      agentDrawerRef,
      delMultiple,



    }
  },
})
</script>


<style scoped>

</style>
