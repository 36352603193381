<template>
  <a-drawer
    title="新增页面"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"

  >
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" ref="formRef" :model="data" @finishFailed="onFinishFailed">
        <a-form-item label="系统名称" name="systemName" :rules="[{ required: true, message: '请输入系统名称' ,trigger: 'change'}]">
          <a-input v-model:value="data.systemName" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="系统编码" name="systemCode" :rules="[{ required: true, message: '请输入系统编码' ,trigger: 'change'}]">
          <a-input v-model:value="data.systemCode" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="系统类型" name="systemType" :rules="[{ required: true, message: '请选择系统类型' ,trigger: 'change'}]">
          <a-select  v-model:value="data.systemType" :options="systemClassify">
        </a-select>
        </a-form-item>
        <a-form-item label="系统域名地址" name="envInterfaceUrl" :rules="[{ required: true, message: '请输入系统域名地址' ,trigger: 'change'}]">
          <a-input placeholder="请输入域名地址" v-model:value="data.envInterfaceUrl" />
        </a-form-item>
        <a-form-item label="系统描述" name="interfaceInfo">
          <a-textarea placeholder="请输入描述信息"  :auto-size="{ minRows: 3, maxRows: 20 }" v-model:value="data.interfaceInfo" :maxlength="200"/>
        </a-form-item>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary"  @click="addData">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
    <!-- <div class="inte-butt">
        <a-button type="primary" @click="addData">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
        <a-button style="margin-left: 50px" @click="onClose">
          <template #icon>
            <ReloadOutlined/>
          </template>
          关闭
        </a-button>
    </div> -->
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs,ref, onBeforeMount} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import { message } from 'ant-design-vue'
import { getDictionaryData } from '../../../utils/request_extend'
export default defineComponent({
  props: {
    handleSearch: {type: Function}
  },
  setup(props) {
    const formRef = ref()
    // 收集用户输入的信息
    const data = reactive({
      // 系统名称
      systemName: '',
      // 系统编码
      systemCode: '',
      // 系统分类
      systemType: '2',
      // 环境接口地址
      envInterfaceUrl : '',
      // 接口信息配置
      interfaceInfo : ''
    })
    const formState = reactive({
    //系统名称
      systemName: '',
      //系统编码
      systemCoding: '',
      //系统分类
      systemClassify: {

      },
    //   环境接口地址
    interfaceAddress : '',
    // 接口信息配置
    interfaceInformation : ''
    })

    const state = reactive({
      visible: false,
      headerName: '',
    })
    const upData = reactive({
      // 系统名称
      systemName: '',
      // 系统编码
      systemCode: '',
      // 系统分类
      systemType: '2',
      // 环境接口地址
      envInterfaceUrl : '',
      // 接口信息配置
      interfaceInfo : ''
    })
    const showDrawer = () => {
      state.visible = true
    }
    // 保存按钮
    const addData = async() => {
       await formRef.value.validate()
      console.log('增加', data)
      request(getRequestURI('env_sync_save'), {data}, true, 'post')
      .then(
        res => {

          if(res.code === 1){
            console.log(res)
            Object.assign(data, upData)
            state.visible = false
            message.success('添加成功')
            props.handleSearch()
          } else {
            console.log(res)
            state.visible = true
            message.warning(res.message)
            // message.warning('当前数据已经创建或者输入框不能为空,请重新核实数据')
          }
        })
    }
    // X 和 取消
    const onClose = () => {
      formRef.value.clearValidate()
      state.visible = false
      Object.assign(data, upData)
    }
    const onFinishFailed = t => {
      console.log('这是啥', t)
    }
    const systemClassify = ref([])
    onBeforeMount(()=>{
      getDictionaryData(systemClassify,'system_type')
    })

    return {
      ...toRefs(state),
      showDrawer,
      onClose,
      addData,
      data,
      upData,
      formState,
      systemClassify,
      onFinishFailed,
      formRef
    //   formItemLayout,
    //   buttonItemLayout
    }
  }
})
</script>

<style scoped>

</style>
