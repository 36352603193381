<template>
  <search-form
    :Condition="condition"
    :ColSpan="8"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addKeyword">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button type="primary" @click="addListKeyword">
      <template #icon>
        <copy-outlined />
      </template>
      批量新增对象及属性
    </a-button>
    <a-button danger @click="delSomeKeyword('sel')">
      <template #icon>
        <DeleteOutlined />
      </template>
      批量删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="tablePaginationChange"
    :loading="loading"
    :row-key="(record) => record.id"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-space>
          <a-button type="primary" @click="editElement(record)" size="small">
            <template #icon>
              <form-outlined />
            </template>
            编辑
          </a-button>
          <a-button
            type="primary"
            @click="delSomeKeyword(record)"
            size="small"
            danger
          >
            <template #icon>
              <delete-outlined />
            </template>
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-drawer
    v-model:visible="addShow"
    :bodyStyle="previewModal"
    width="65%"
    destroyOnClose
    :maskClosable="false"
    :title="title"
  >
    <template #extra>
      <a-button type="primary" @click="handleOk">保存</a-button>
    </template>
    <AddAndUpdate
      ref="adAndUpdateRef"
      @handleSearch="handleSearch"
    ></AddAndUpdate>
  </a-drawer>
  <a-drawer
    v-model:visible="addListShow"
    :bodyStyle="previewModal"
    width="65%"
    destroyOnClose
    :maskClosable="false"
    title="批量新增对象及属性"
  >
    <template #extra>
      <a-button type="primary" @click="handleOk2">保存</a-button>
    </template>
    <AddList ref="addListRef" @handleSearch="handleSearch"></AddList>
  </a-drawer>
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import { defineComponent, reactive, toRefs, computed, ref, onMounted, nextTick } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request'
import { getExtendUri } from '../../../utils/request_extend'
import AddAndUpdate from './addAndUpdate.vue'
import AddList from './batchAdd.vue'

export default defineComponent({
  components: {
    SearchForm,
    AddAndUpdate,
    AddList
  },
  setup () {
    const state = reactive({
      title: '关键字增加',
      addShow: false,
      addListShow: false,
      searchNew: {},
      selectedRowKeys: [],
      condition: {
        keywordName: {
          label: '关键字名称',
        },
        keywordCode: {
          label: '关键字编码',
        },
        keywordType: {
          label: '关键字类型',
          type: 'select',
          data: []
        },
      },
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 70,
          align: 'left',
          dataIndex: 'index',
        },
        {
          title: '名称',
          width: 130,
          key: 'keywordName',
          dataIndex: 'keywordName',
        },
        {
          title: 'id',
          width: 140,
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '编码',
          width: 130,
          key: 'keywordCode',
          dataIndex: 'keywordCode',
        },
        {
          title: '类型',
          width: 100,
          key: 'keywordTypeName',
          dataIndex: 'keywordTypeName',
        },
        {
          title: '描述',
          width: 170,
          key: 'describes',
          dataIndex: 'describes',
        },
        {
          title: '扩展',
          width: 170,
          key: 'completionStatement',
          dataIndex: 'completionStatement',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'left',
          width: 150,
        },
      ],


    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ruleKeyword_list'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    //查询
    const handleSearch = (filters = 'reload') => {
      state.addShow = false
      state.addListShow = false
      if (filters !== 'reload') {
        state.searchNew = filters
      }
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: state.searchNew,
      })
    }
    // 页码变化
    const tablePaginationChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNew,
      })
    }
    // 选择
    const onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const adAndUpdateRef = ref()
    const addListRef = ref()
    // 新增
    const addKeyword = () => {
      state.addShow = true
      state.title='关键字增加'
      nextTick(() => {
        adAndUpdateRef.value.keywordOptions = state.condition.keywordType.data
      })
    }
    const addListKeyword = () => {
      state.addListShow = true
    }
    // 删除 / 批量删除
    const delSomeKeyword = (record) => {
      let ids = []
      if (record !== 'sel') {
        ids.push(record.id)
      } else {
        console.log(ids)
        if (!state.selectedRowKeys.length) return message.warning('请选择数据')
        ids = [...state.selectedRowKeys]
      }
      Modal.confirm({
        title: '删除',
        content: record !== 'sel' ? '是否删除关键字"' + record.keywordCode + '"?' : '是否删除已选择的关键字？',
        onOk: () => {
          request(getRequestURI('ruleKeyword_delete'), { ids }).then(res => {
            if (res.code === 1) {
              handleSearch()
              message.success('删除成功')
              state.selectedRowKeys = []
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })

    }
    //编辑
    const editElement = (oldData) => {
      state.title = '关键字修改'
      state.addShow = true
      nextTick(() => {
        adAndUpdateRef.value.keywordOptions = state.condition.keywordType.data
        adAndUpdateRef.value.setKeywordData(oldData)
      })
    }
    // 弹窗的确认
    const handleOk = async () => {
      await adAndUpdateRef.value.handelAddAndUpdate()

    }
    const handleOk2 = async () => {
      await addListRef.value.handelAddList()
    }

    // 获取关键字类型
    const getKeyWordType = () => {
      request(getExtendUri('dictionary_query_byType'), { data: { types: ['rule_key'] } }).then(
        res => {
          state.condition.keywordType.data = res.result['rule_key']
        })
    }


    const previewModal = {
      'height': '100%',
      'overflow': 'auto'
    }
    onMounted(() => {
      getKeyWordType()
    })

    return {
      ...toRefs(state),
      handleSearch,
      tablePaginationChange,
      addKeyword,
      addListKeyword,
      delSomeKeyword,
      onSelectChange,
      editElement,
      handleOk,
      handleOk2,
      getKeyWordType,
      adAndUpdateRef,
      addListRef,
      previewModal,
      pagination,
      dataSource,
      loading,
    }
  }
})
</script>

<style scoped>
</style>
