<style scoped>
.gutter-row {
  color: #00a0e9;
  text-align: center;
  padding: 15px 0;
}
.btn-bar {
  position: fixed;
  background: #FFFFFF;
  z-index: 999;
  height: 32px;
  line-height: 32px;
  margin-top: -20px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #eee;
}
</style>

<template>
  <div style="position: relative">
    <div class="btn-bar">
      <a href="#Filled">实底风格图标</a>
      <a-divider type="vertical" />
      <a href="#Outlined">线框风格图标</a>
      <a-divider type="vertical" />
      <a href="#TwoTone">双色风格图标</a>
    </div>
    <a-divider id="Filled" style="color: #00a0e9;">实底风格图标</a-divider>
    <a-row :gutter="16">
      <template v-for="(item, index) in iconList.filled" :key="index">
        <a-col class="gutter-row" :span="6">
          <component :is="iconComponent(item)" style="font-size: 24px;"></component><br/>
          <label>{{ item }}</label>
        </a-col>
      </template>
    </a-row>
    <a-divider id="Outlined" style="color: #00a0e9;">线框风格图标</a-divider>
    <a-row :gutter="16">
      <template v-for="(item, index) in iconList.outlined" :key="index">
        <a-col class="gutter-row" :span="6">
          <component :is="iconComponent(item)" style="font-size: 24px;"></component><br/>
          <label>{{ item }}</label>
        </a-col>
      </template>
    </a-row>
    <a-divider id="TwoTone"  style="color: #00a0e9;">双色风格图标</a-divider>
    <a-row :gutter="16">
      <template v-for="(item, index) in iconList.twoTone" :key="index">
        <a-col class="gutter-row" :span="6">
          <component :is="iconComponent(item)" style="font-size: 24px;"></component><br/>
          <label>{{ item }}</label>
        </a-col>
      </template>
    </a-row>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import * as antIcons from '@ant-design/icons-vue'

export default defineComponent({
  components: {},
  setup() {
    let _Filled = [], _Outlined = [], _TwoTone = []
    Object.keys(antIcons).forEach((key) => {
      if (key && key.endsWith('Filled')) {
        _Filled.push(key)
      } else if (key && key.endsWith('Outlined')) {
        _Outlined.push(key)
      } else if (key && key.endsWith('TwoTone')) {
        _TwoTone.push(key)
      }
    })
    const state = reactive({
      iconList: {
        filled: _Filled,
        outlined: _Outlined,
        twoTone: _TwoTone
      }
    })
    const iconComponent = (name) => {
      return antIcons[name]
    }
    return {
      iconComponent,
      ...toRefs(state),
    }
  }
})
</script>
