<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew ? '新增Excel表头数据' : '修改Excel表头数据'"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRules"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-form-item name="id" v-if="false">
          <a-input v-model:value="formData.id" />
        </a-form-item>
        <a-col span="20">
          <a-form-item label="Excel表头信息" name="tableTitle" required>
            <a-input v-model:value="formData.tableTitle" placeholder="请输入Excel表头信息"/>
          </a-form-item>
        </a-col>
        <a-col span="20">
          <a-form-item label="映射值信息（被保人对象属性）" name="mappingValue" required>
            <a-input
              v-model:value="formData.mappingValue"
              placeholder="请输入映射值信息（被保人对象属性）"
            />
          </a-form-item>
        </a-col>
        <a-col span="20">
          <a-form-item label="格式描述" name="formatDescription" required>
            <a-input v-model:value="formData.formatDescription" placeholder="请输入格式描述: 表示该字段应该输入什么样的格式"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {ReloadOutlined, SaveOutlined} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
export default defineComponent({
  components: {
    ReloadOutlined,
    SaveOutlined,
  },
  props:{
    handleSearch:{
      type: Function
    },
    selectData:{
      type: Object,
      default:() => {}
    },
    current:{
      type: Number,
      default:() => 1
    }
  },
  setup(props, {expose}) {
    const formRef = ref()
    const state = reactive({
      visible: false,
      isNew: true,
    })
    const formData = reactive({
      id: '',
      tableTitle: '',
      mappingValue: '',
      formatDescription: ''
    })
    const formRules = {
      dataItem: [{required: true,message: '请填写数据项', trigger: 'blur'}],
      dataItemDescription: [{required: true,message: '请填写数据项说明', trigger: 'blur'}],
      sort: [{required: true, trigger: 'change',message: '请选择本级排序' }],
    }

    const showDrawer = (type, oldData = {}) => {
      state.visible = true
      state.isNew = type === 'save'
      Object.keys(formData).forEach(key => formData[key] = oldData[key] || formData[key])
    }
    const onClose = () => {
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.visible = false
    }
    const onSave = async () => {
      await formRef.value.validate()
      let requestURI = getRequestURI('excel_update')
      if (state.isNew) {
        requestURI = getRequestURI('excel_insert')
      }
      request(requestURI, {data:formData}).then(
        (result) => {
          if(result.code === 1) {
            message.success('保存成功')
            props.handleSearch(props.selectData,props.current)
            onClose()
          }else{
            message.warning(result.message)
          }
        }
      )
    }
    const onFormClean = () => {
      formRef.value.resetFields()
      formRef.value.clearValidate()
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    expose({
      showDrawer,
    })
    return {
      onFormClean,
      filterOption,
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      showDrawer,
    }
  },
})
</script>
