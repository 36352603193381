<style scoped>
</style>
<template>
  <a-drawer :title="titleText" :visible="showEditRole" :label-col="labelCol" width="50%" @close="close">
    <a-form :model="formData">
      <a-input v-model:value="formData.id"  v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="名称" name="roleName" required>
            <a-input  v-model:value="formData.roleName" placeholder="请输入角色名称" />
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="备注" name="remark">
            <a-input v-model:value="formData.remark" placeholder="请输入备注" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-button type="primary" @click="saveOrUpdate"><SaveOutlined/>保存</a-button>
    </template>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {},
  setup(props, context) {
    const state = reactive({
      titleText: '新增角色',
      showEditRole: false,
      formData: {
        id: '',
        roleName: '',
        remark: ''
      },
      labelCol: {
        style: {
          width: '120px',
        },
      }
    })
    const showRole = (_role) => {
      if (_role && _role.id && _role.id !== '') {
        state.titleText = '角色修改'
        state.formData.id = _role.id
        state.formData.roleName = _role.roleName
        state.formData.remark = _role.remark
      } else {
        state.titleText = '新增角色'
        state.formData.id = ''
        state.formData.roleName = ''
        state.formData.remark = ''
      }
      state.showEditRole = true
    }
    const close = () => {
      state.showEditRole = false
    }
    const saveOrUpdate = () => {
      let uri = getRequestURI('role_save')
      if (state.formData.id && state.formData.id !== '') {
        uri = getRequestURI('role_update')
      }
      request(uri, {data:state.formData}).then(result => {
        if (result.code === 1) {
          state.showEditRole = false
          context.emit('callback')
          message['success']('保存成功')
        } else {
          message['error'](result.message)
        }
      })
    }
    context.expose({
      showRole
    })
    return {
      ...toRefs(state),
      close,
      saveOrUpdate
    }
  }
})
</script>
