<style scoped>
.column-title {
  background: #e0e0e0;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>

<style>
.menu_content_scroll2 {
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 6px;
}
</style>
<template>
  <div class="column-title">
    <span>菜单列表{{role.id?`(${role.roleName})`:''}}</span>
    <span>
      <a-button shape="circle" @click="saveSource" :loading="loading">
        <template #icon> <SaveOutlined/> </template>
      </a-button>
    </span>
  </div>
  <a-tree class="menu_content_scroll2" :default-expand-all="defaultExpandAll"
          v-model:checked-keys="checkedKeys"
          :tree-data="menus_data" checkable>
  </a-tree>
</template>

<script>
import {defineComponent, nextTick, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {},
  setup(props, context) {
    const state = reactive({
      loading: false,
      defaultExpandAll: false,
      role: {},
      menus_data: []
    })
    const checkedKeys = ref({})
    const data_list = {}
    const saveSource = () => {
      console.log('checkedKeys', checkedKeys)
      if (state.role && state.role.id) {
        if (checkedKeys.value.length > 0) {
          let sourceList = []
          checkedKeys.value.forEach(s => {
            const node = data_list[s]
            sourceList.push({
              actionId: (node.isMenu === 'N' ? node.id : ''),
              menuId: (node.isMenu === 'Y' ? node.id : node.menuId),
              isMenu: node.isMenu
            })
          })
          let param = {
            roleId: state.role.id,
            sourceList: sourceList
          }
          state.loading = true
          request(getRequestURI('role_saveMenus'), {data:param}).then(result => {
            state.loading = false
            if (result.code === 1) {
              roleMenu(state.role)
              message['success']('保存成功')
            } else {
              message['error'](result.message)
            }
          })
        }
      } else {
        Modal.warning({
          title: '保存资源',
          content: '请先选择角色'
        })
      }
    }
    const roleMenu = (_role) => {
      state.role = _role
      request(getRequestURI('role_menuQuery'), {data:{roleId: _role.id}}).then(res => {
        state.menus_data.length = 0
        const checkedArr = []
        state.menus_data = getMenu(res.result, checkedArr)
        nextTick(()=> {
          checkedKeys.value = checkedArr
        })
        state.defaultExpandAll = true
      })
    }
    const getMenu = (_e, checkedArr) => {
      if (_e && _e.length > 0) {
        let arr = []
        _e.forEach(e => {
          let node = {
            id: e.id,
            key: e.id,
            title: e.name,
            isMenu: e.isMenu,
            menuId: e.menuId,
            children: getMenu(e.submenus || e.actions, checkedArr),
          }
          data_list[e.id] = node
          arr.push(node)
          if (e.status === 1) {
            checkedArr.push(e.id)
          }
        })
        return arr
      } else {
        return []
      }
    }
    context.expose({
      roleMenu
    })
    return {
      ...toRefs(state),
      saveSource,
      checkedKeys
    }
  }
})
</script>
