<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew ? '新增字典数据' : '修改字典数据'"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRules"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="数据类型" name="type" required>
            <a-select allow-clear show-search :filter-option="filterOption"  :options="typeData" v-model:value="formData.type" placeholder="请选择">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12"></a-col>
        <a-col span="12">
          <a-form-item label="数据项" name="code" required>
            <a-input
              v-model:value="formData.code"
              placeholder="数据类型"
              :disabled="!isNew"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="数据项说明" name="name" required>
            <a-input
              v-model:value="formData.name"
              placeholder="数据名称"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="状态" name="status" required>
            <a-select
              v-model:value="formData.status"
              placeholder="请选择状态"
              :options="statusList"
            >
<!--              <a-select-option :value="1">有效</a-select-option>-->
<!--              <a-select-option :value="0">无效</a-select-option>-->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="显示顺序" name="orderFlag" required>
            <a-input-number id="inputNumber" v-model:value="formData.orderFlag" :min="0" :max="999"  style="width: 100%"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="数据子类型" name="subType">
            <a-select v-model:value="formData.subType" placeholder="请选择" :options="typeDatas">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="图标" name="icon">
            <a-select v-model:value="formData.icon" placeholder="请选择">
              <a-select-option value="Y">启用</a-select-option>
              <a-select-option value="N">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {ReloadOutlined, SaveOutlined} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {reloadDictionaryData} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    ReloadOutlined,
    SaveOutlined,
  },
  props:{
    typeData:{
      type: Array,
      default() {
        return []
      }
    },
    handleSearch:{
      type: Function
    },
    getTypeData:{
      type: Function
    }
  },
  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      icon:'',
      code: '',//数据项
      status: '',//用户状态
      type: '',//数据类型
      subType: '',//数据子类型
      name: '',//数据项说明
      orderFlag: 1,//显示顺序
      typeName: ''
    })
    const formRules = {
      dataItem: [{required: true,message: '请填写数据项', trigger: 'blur'}],//数据项
      dataItemDescription: [{required: true,message: '请填写数据项说明', trigger: 'blur'}],//数据项说明
      sort: [{required: true, trigger: 'change',message: '请选择本级排序' }],//数据子类型
      userStatus: [{required: true, trigger: 'change',message: '请选择状态'}],//用户状态
      dataType: [{required: true, trigger: 'change',message: '请选择数据类型'}],//数据类型
    }
    const state = reactive({
      statusList: [],
      visible: false,
      typeDatas: [],
      isNew: true,
      transferStyle: {width: '50%'},
      roleData: [],
    })
    const showDrawer = (oldData = {}) => {
      init()
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(oldData).forEach(key => formData[key] = oldData[key])
      formData.status += ''
    }
    const onClose = () => {
      state.visible = false
      formRef.value.clearValidate()
      Object.assign(formData, {
        id: '',
        icon:undefined,
        code: '',//数据项
        status: '',//用户状态
        type: '',//数据类型
        subType: undefined,//数据子类型
        name: '',//数据项说明
        orderFlag: 1,//显示顺序
        typeName: ''
      })
      console.log(formData)
    }
    const onFormClean = () => {
      if(state.isNew){
        formRef.value.resetFields()
      } else {
        formRef.value.clearValidate()
        Object.assign(formData, {
          icon:'',
          status: '',//用户状态
          type: '',//数据类型
          subType: '',//数据子类型
          orderFlag: 1,//显示顺序
        })
      }
    }
    const value = ref()
    const onSave = async () => {
        await formRef.value.validate()
        props.typeData.forEach(item =>{
          if(item.value === formData.type){
            formData.typeName = item.label
          }
        })
        if (formData.type === 'FWNUM') {
          if (formData.name && formData.name.length > 10) {
            message.error('数据项说明输入字符不能超过10位')
            return
          }
        }
        let requestURI = getRequestURI('dictionary_update')
        if (state.isNew) {
          // 新增
          requestURI = getRequestURI('dictionary_save_type')
        }
        request(requestURI, {data:formData}).then(
            (result) => {
              if(result.code === 1) {
                reloadDictionaryData(formData.type)
                message.success('保存成功')
                onClose()
                formData.id = ''
                props.handleSearch('reload')
                props.getTypeData()
              }else{
                message.warning(result.message)
              }
            }
          )
    }
    const init = function() {
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['status']}}).then(res => {
        state.statusList = res.result.status
      })
      state.typeDatas = []
      props.typeData.forEach(item => {
        if(item.value !== 'System') {
          state.typeDatas.push(item)
        }
      })
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    expose({
      showDrawer,
    })
    return {
      filterOption,
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      showDrawer,
      value,
    }
  },
})
</script>
