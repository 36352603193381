<style scoped>
.bg {
  margin-bottom: 20px;
}
</style>

<template>
  <a-drawer
    title="添加保险产品捆绑服务产品配置"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
        <search-form
          :Condition="condition"
          :ColSpan="12"
          @callback="handleSearch"
        ></search-form>

        <a-table
          class="bg"
          :columns="columns"
          :data-source="dataSource ? dataSource['result'] : []"
          :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
			type: 'radio',
		}"
          :row-key="(record) => record.id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
        >
          <template #bodyCell="{ column, index }">
            <template v-if="column.key === 'index'">
              {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
            </template>
          </template>
        </a-table>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>
  </a-drawer>

</template>

<script>
import {CloseOutlined, SaveOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
// import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message} from 'ant-design-vue'
// import {message} from 'ant-design-vue'


export default defineComponent({
  components: {
    CloseOutlined,
    SaveOutlined,
    SearchForm,
  },

  setup(props, {expose}) {
    const userDrawerRef = ref()
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})
    const state = reactive({
      visible: false,
      columns: [
        {
          title: '序号',
          width: 60,
          align: 'left',
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '服务产品名称',
          width: 100,
          align: 'center',
          key: 'serviceProductName',
          dataIndex: 'serviceProductName',
        },
        {
          title: '服务产品编码',
          width: 100,
          align: 'center',
          key: 'serviceProductCode',
          dataIndex: 'serviceProductCode',
        }
      ],
      condition: {
        serviceProductName: {
          label: '服务产品名称',
          type: 'input',
          default: '',
          data: []
        },
        serviceProductCode: {
          label: '服务产品编码',
          type: 'input',
          default: '',
          data: []
        },
        isRequiredBinding: {
          label: '是否捆绑必选',
          type: 'select',
          urlKey:'queryDictionaryType',
          queryParam:{type:'yes_no'}
        },
      },
      selectedRowKeys: [],
      dataSource: [],
      data:[],
      result: [],
      searchNow: {},//分页
      productInfoId:'',//绑定的productInfoId
      serviceProductId:''//绑定的serviceProductId
    })
    const serveNameShow = ref(false)

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('serviceProductInfo'))
    const handleSearch = function (filters) {
      console.log('保险绑定服务 服务查询参数==', filters)
      state.searchNow = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },

        sorter,
        data: state.searchNow,
      })
    }
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      selectedRowKeys.forEach(item => {
        state.serviceProductId = item
      })
    }
    //抽屉
    const showDrawer = (oldData,productInfoId) => {
      state.productInfoId=productInfoId
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    //关闭 清空
    const onClose = () => {
      state.visible = false
      state.dataSource = []
      // formRef.value.clearValidate(),
      // formRef.value.resetFields(),
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
    //保存
    const onSave = () => {
      request(getRequestURI('service-agent'),
        {
          data: {
            productRelationFormList: [
              {
                productInfoId: state.productInfoId,
                serviceProductId: state.serviceProductId
              }
            ]
          },
        }, true, 'post').then(
        (result) => {
          message.success(result.message + '服务保险绑定成功')
          console.log(result, '服务保险绑定')
          state.result = result.result
          state.visible = false
        }
      ).catch(e => {
        console.log(e)
      })
    }
    expose({
      showDrawer,
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      loading,
      dataSource,
      handleSearch,
      onSelectChange,
      handleTableChange,
      pagination,
      serveNameShow,
      checked: ref(false),
      isCheckInfo,
      userDrawerRef,
    }
  },
})
</script>
