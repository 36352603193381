<style scoped>
.factor{
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 20px;
}
.btn-bar {
  margin: 10px;
}
</style>

<template>
  <a-drawer
    :title=panDuan
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="服务产品名称:" name="serviceProductName">
            <a-input v-model:value="formData.serviceProductName" :disabled="zhuangTai1=='details'" placeholder="请输入服务产品名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="服务产品编码:" name="serviceProductCode">
            <a-input v-model:value="formData.serviceProductCode" :disabled="zhuangTai1=='details'" placeholder="请输入服务产品编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="服务产品分类:" name="serviceProductType">
            <a-select v-model:value="formData.serviceProductType" allow-clear :options="typeList" :disabled="zhuangTai1=='details'" placeholder="请输入服务产品分类" clearable>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="份数:" name="num">
            <a-select v-model:value="formData.num" allow-clear :options="numList" @change="handleChange1"  :disabled="zhuangTai1=='details'" placeholder="请输入份数" clearable>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="服务产品特色:" name="serviceProductFeature">
            <a-textarea v-model:value="formData.serviceProductFeature" :rows="4" :disabled="zhuangTai1=='details'"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="服务产品说明:" name="serviceProductDescription">
            <a-textarea v-model:value="formData.serviceProductDescription" :rows="4" :disabled="zhuangTai1=='details'"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否单独销售:" name="isOnly">
            <a-select v-model:value="formData.isOnly" allow-clear
                      :disabled="zhuangTai1=='details'" clearable :options="isOnlyList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否捆绑必选:" name="isRequiredBinding">
            <a-select v-model:value="formData.isRequiredBinding" allow-clear
                      :disabled="zhuangTai1=='details'" clearable :options="isRequiredBindingList">
            </a-select>
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>

    <br/>
    <br/>
    <div class="factor">
      <a-form
        :model="formData"
        layout="vertical"
        ref="formRef"
        autocomplete="off"
      >
        <a-row :gutter="10">
          <a-col span="12">
            <a-form-item
              :rules="[{max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
              label="要素名称:" name="serviceElementName">
              <a-input v-model:value="formData.serviceElementName" :disabled="zhuangTai1=='details'"/>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item
              :rules="[{ pattern:/^[0-9a-zA-Z]/, message: '支持数字和字母格式' ,trigger: 'change'},
                {max: 20, message: '输入长度不能超过20' ,trigger: 'change'}]"
              label="要素编码:" name="serviceElementCode">
              <a-input v-model:value="formData.serviceElementCode" :disabled="zhuangTai1=='details'"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="btn-bar">
        <a-button @click="addElements" :disabled="zhuangTai1=='details'">
          添加
        </a-button>
        <a-button @click="deletes" :disabled="zhuangTai1=='details'">
          删除
        </a-button>
      </div>

      <a-table
        :columns="columnsOne"
        :data-source="serviceElementNameList"
        :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,

		}"
        :row-key="(record) => record.id"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, index }">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
          <template v-if="column.key === 'serviceElementName${i+1}'">
            <a-input></a-input>
          </template>
          <template v-if="column.key === 'price'">
            <a-input></a-input>
          </template>
        </template>
      </a-table>

      <div class="btn-bar">
        <a-button @click="createRate" :disabled="zhuangTai1=='details'">
          创建费率表
        </a-button>
        <a-button @click="download(record)" :disabled="zhuangTai1=='details'">
          模板下载
        </a-button>
        <a-button @click="deleteRate" :disabled="zhuangTai1=='details'">
          删除费率表
        </a-button>
        <a-col span="12" v-if="zhuangTai1!=='details'" >
          <a-form-item  label="费率表模板导入" name="url" >
            <upload-files ref="clearInput" name="formData.url" @fileChange="uploadCosFileChange" ></upload-files>
          </a-form-item>
        </a-col>
      </div>
      <a-table
        :columns="columnsTwo"
        :data-source="dataSourceListTwo"
        :row-selection="rowSelection"
        :row-key="(record) => record.id"
        :pagination="pagination"
        @change="handleTableChange"
        v-if="tableTitle"
      >
        <template #bodyCell="{ column, index }">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>

          </template>
      </a-table>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>
  </a-drawer>

</template>

<script>
import {SaveOutlined, CloseOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onMounted} from 'vue'
import {message,Modal} from 'ant-design-vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import uploadFiles from '../../../components/extended/uploadFile'

export default defineComponent({
  components: {
    SaveOutlined,
    CloseOutlined,
    uploadFiles
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    handleMainSearch:{type: Function}
  },

  setup(props, {expose}) {

    const formRef = ref()
    const formData = reactive({
      id: '',
      isOnly: '',
      isRequiredBinding: '',
      num: undefined,
      serviceProductCode: '',
      serviceProductDescription: '',
      serviceProductElementList: [
        {
          serviceElementCode: '',
          serviceElementName: ''
        }
      ],
      serviceProductFeature: '',
      serviceProductName:'',
      serviceProductType: undefined,
      url: '',
      serviceElementCode:'',//要素编码
      serviceElementName:'',//要素名称
    })
    const state = reactive({
      tableTitle:false,//表格是否展示
      ziDuan: false,
      typeList: [
        {
          label: '实物',
          value: 1
        },
        {
          label: '虚拟',
          value: 2
        }
      ],
      isOnlyList: [],
      isRequiredBindingList: [],
      supNameList: [],//供应商名称
      status: '',
      selectedRowKeys: [],//多选反选
      data: [],//批量删除
      dataSource: [],
      zhuangTai1: null,
      zhuangTai2: false,
      condition: {
        fileType: {
          label: '资料类型',
          type: 'select',
          default: '',
          data: [
            {
              label: '投保须知',
              value: 1
            },
            {
              label: '产品条款',
              value: 2
            },
          ]
        }
      },
      columnsOne: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '要素编码',
          dataIndex: 'serviceElementCode',
          key: 'serviceElementCode',
          align: 'left',
          fixed: 'left'
        },
        {
          title: '要素名称',
          dataIndex: 'serviceElementName',
          key: 'serviceElementName',
        }
      ],
      columnsTwo: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        }
      ],
      visible: false,
      isNew: true,
      // dataSourceListOne: [],
      dataSourceListTwo: [],
      serviceElementNameList: [],
      numList:[],//份数

    })
    const {
      run,
      current,
      pageSize,
      total,
    } = requestUsePagination(
      getRequestURI('user_list'),
      {},
      {
        mock: false,
        code: 1,
        message: 'success',
        pagination: {
          total: 200,
          current: 1,
          pageSize: 10,
        },
      }
    )
    //复选
    const onSelectChange = (selectedRowKeys, selectedRowrode) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      console.log(selectedRowrode, 'abc')
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        'pagination.current': pag?.current,
        'pagination.pageSize': pag.pageSize,
        sorter,
        ...filters,
      })
    }
    //份数
    const setSelectOptions = () =>{
      for (let i = 0; i < 20; i++) {
        state.numList.push({
          label: i+1,
          value: i+1,
        })
      }
    }

    const handleChange1 = value =>{
      console.log(value)
    }
    onMounted(()=>{
      setSelectOptions()
    })

    //上传 导入
    const uploadCosFileChange = (data) => {
      formData.url = data.url
    }
    //添加
    let i = 0
    const addElements = () => {
      if (formData.serviceElementName !== '' && formData.serviceElementCode !== '') {
        const {serviceElementName, serviceElementCode} = formData
        i++
        state.serviceElementNameList.push({
          serviceElementName, serviceElementCode,id:i
        })
      }
      Object.assign(formData,{
        serviceElementName:'',
        serviceElementCode:''
      })
      console.log(state.serviceElementNameList, '0000000000')
    }
    //删除
    const deletes = () => {
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          state.selectedRowKeys.forEach((item) => {
            state.serviceElementNameList.forEach((item1, index) => {
              if (item1.id == item) {
                state.serviceElementNameList.splice(index, 1)
                // message['success']('删除成功')
              }
            })
          })
        }
      })
    }
    //title判断状态
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '服务产品项目新增'
      } else if (props.status == 'details') {
        return '服务产品项目查看'
      } else {
        return '服务产品项目编辑'
      }
    })
    const activeKey = ref('1')
    //组件传值
    const showDrawer = (oldData = {}, newData = {}) => {
      state.zhuangTai1 = newData
      console.log(oldData, 111155551)
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    //创建费率表
    const createRate = () => {
      state.tableTitle=true

      const arr1 =  state.serviceElementNameList
      const arr2= {}

      for (let i = 0; i<arr1.length;i++){
        arr2[`serviceElementName${i+1}`] = arr1[i].serviceElementName
      }
      // state.columnsTwo
      for (let i = 0;i<arr1.length;i++){
        state.columnsTwo.push({
          title:arr1[i].serviceElementName,
          dataIndex:`serviceElementName${i+1}`,
          key:`serviceElementName${i+1}`,
        })
      }
      state.columnsTwo.push({
        title: '价格',
        dataIndex: 'price',
        key: 'price',
        align: 'left',
        fixed: 'right'
      })

    }
    //删除费率表
    const deleteRate = () => {
      state.tableTitle=false
      state.columnsTwo.splice(1)
      state.serviceElementNameList = []
      //splice
    }
    //模板下载
    const download = (recode) => {
      console.log(recode)
      let obj = []
      state.serviceElementNameList.forEach(item => {
        obj.push(item.serviceElementName)
      })
      console.log(obj)
      obj.unshift('序号')
      obj.push('价格')
      request(getRequestURI('service-downLoadTemplate'), {
        data: {
         serviceElementNameList: obj
        },
      }).then(res=>{
        window.location = res.result
        // modifyUser() 列表初始化
        console.log(res,'模板下载')
        state.visible=true
      })

    }
    //关闭
    const onClose = () => {
      state.targetKeys = []
      state.visible = false
      state.serviceElementNameList = []
      formData.isOnly= ''
      formData.isRequiredBinding= ''
      formData.num= undefined,
      formData.serviceProductCode= ''
      formData. serviceProductName=''
      formData.serviceProductDescription= ''
      formData.serviceProductFeature= ''
      formData.serviceProductType= undefined
      formData.url= ''
    }

    //保存按钮
    const clearInput = ref()
    const onSave = async () => {

      if (props.status == 'edit') {
        delete formData.serviceElementName
        delete formData.serviceElementCode
        let obj = {
          data: formData
        }
        const uri = getRequestURI('serviceProductInfo-update')
        request(uri, obj).then(res => {
          console.log(res, '服务产品更新修改')
          if (res.code === 1) {
            message.success('服务产品更新修改' + res.message)
            onClose()
            props.handleMainSearch({})
          } else {
            message.error( res.message)
          }
        })
      } else {
        delete formData.serviceElementName
        delete formData.serviceElementCode
        let obj = {
          data: formData
        }
        const uri = getRequestURI('serviceProductInsert')
        request(uri, obj).then(res => {
          console.log(res, '服务产品新增')
          if (res.code === 1) {
            message.success('服务产品新增'+ res.message)
            formRef.value.resetFields()
            onClose()
            props.handleMainSearch({})
            clearInput.value.cleInput.value = ''
          } else{
            message.error('服务产品新增失败'+ res.message)
          }
        })
      }
    }
    const init = function() {
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['yes_no']}}).then(res => {
        state.isOnlyList = res.result.yes_no,
        state.isRequiredBindingList = res.result.yes_no
      })
    }
    init()
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      headers: {
        authorization: 'authorization-text'
      },
      panDuan,
      pagination,
      handleTableChange,
      activeKey,
      deleteRate,
      createRate,
      onSelectChange,
      download,
      addElements,
      setSelectOptions,
      handleChange1,
      uploadCosFileChange,
      deletes,
      clearInput
    }
  },
})
</script>
