<style scoped>
.bg {
  margin-bottom: 20px;
}
</style>

<template>
  <a-drawer
    title="添加服务产品捆绑保险产品配置"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
        <search-form
          :Condition="condition"
          :ColSpan="12"
          @callback="handleSearch"
        ></search-form>
        <a-table
          class="bg"
          :columns="columns"
          :data-source="dataSource ? dataSource['result'] : []"
          :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
			type: 'radio',
		}"
          :row-key="(record) => record.id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
        >
          <template #bodyCell="{ column, index }">
            <template v-if="column.key === 'index'">
              {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
            </template>
          </template>
        </a-table>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
      </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onMounted} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message} from 'ant-design-vue'


export default defineComponent({
  components: {
    CloseOutlined,
    SaveOutlined,
    SearchForm,
  },
  setup(props, {expose}) {
    const userDrawerRef = ref()
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})
    const state = reactive({
      visible: false,
      serviceProductId: '',//绑定保存的serviceProductId
      productInfoId: '',//绑定保存的productInfoId
      columns: [
        {
          title: '序号',
          width: 60,
          align: 'left',
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },

      ],
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey:'querySupplier',
          value:'id',
          default: '',
          data: []
        },
        productCode: {
          label: '产品编码',
          type: 'input',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
      },
      selectedRowKeys: [],
      dataSource: [],
      data:[],
      result: [],
      searchNow: {}//分页
    })
    const serveNameShow = ref(false)
    //添加 服务绑定保险查询
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))

    const handleSearch = function (filters) {
      console.log('供应商查询参数==', filters)
      state.searchNow = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      selectedRowKeys.forEach(item => {
        state.productInfoId = item
      })
    }
    //产品名称下拉
    const getProductName = () => {
      request(getRequestURI('select'), {
        data: {
          productCode: '',
          productId: null,
          supProductCode: '',
          supplierId: null,
          systemCode: ''
        },
      }, true, 'post').then(
        (result) => {
          console.log('产品下拉选择的结果', result)
          const arr = []
          const res = result.result
          const label = 'productName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])

          for (let i = 0; i < sysName.length; i++) {
            arr.push({label: sysName[i].productName, value: sysName[i].id})
          }
          state.condition.productId.data = arr
        }
      )
    }
    onMounted(() => {
      getProductName()
    })
    //抽屉
    const showDrawer = (oldData,serviceProductId) => {
      state.serviceProductId = serviceProductId
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    //关闭 清空
    const onClose = () => {
      state.visible = false
      state.dataSource = []
      // formRef.value.clearValidate(),
      // formRef.value.resetFields(),
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
    //保存
    const onSave = () => {

      request(getRequestURI('service-agent'),
        {
          data: {
            productRelationFormList: [
              {
                productInfoId: state.productInfoId,
                serviceProductId: state.serviceProductId
              }
            ]
          },
        }, true, 'post').then(
        (result) => {
          message.success(result.message + '服务保险绑定成功')
          console.log(result, '服务保险绑定')
          state.result = result.result
          state.visible = false
        }
      ).catch(e => {
        console.log(e)
      })

    }
    expose({
      showDrawer,
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      loading,
      dataSource,
      handleSearch,
      onSelectChange,
      handleTableChange,
      pagination,
      serveNameShow,
      checked: ref(false),
      isCheckInfo,
      userDrawerRef,
      getProductName
    }
  },
})
</script>
