<style scoped>
</style>

<template>
  <a-drawer
    :title="title"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      :rules="formRules"
      autocomplete="off"
    >
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="双录系统类型" name="drSystemType" required>
            <a-select allow-clear show-search :filter-option="filterOption"   :disabled="flag==='details'" :options="typeData" v-model:value="formData.drSystemType" placeholder="请选择">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="双录系统名称" name="drSystemName" required>
            <a-input
              v-model:value="formData.drSystemName"
              placeholder="双录系统名称"
              :disabled="flag==='details'"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="双录账号类型" name="drAccountType" required>
            <a-select allow-clear show-search :filter-option="filterOption"  :disabled="flag==='details'" :options="typeData1" v-model:value="formData.drAccountType" placeholder="请选择">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="双录系统状态" name="drSystemStatus" required>
            <a-select allow-clear show-search :filter-option="filterOption"  :disabled="flag==='details'" :options="typeData2" v-model:value="formData.drSystemStatus" placeholder="请选择">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <!--          <a-form-item label="双录保险公司" name="supNameList" v-if="flag==='add'" required>-->
          <!--            <a-select  allow-clear show-search  mode="multiple" :filter-option="filterOption"   :options="typeData3" v-model:value="formData.supNameList" placeholder="请选择">-->
          <!--            </a-select>-->
          <!--          </a-form-item>-->
          <!--         -->

          <a-form-item label="双录保险公司" v-if="flag==='add'" name="supNameList" required>
            <a-select allow-clear
                      show-search
                      mode="multiple"
                      :filter-option="filterOption"
                      :options="typeData3"
                      v-model:value="formData.supNameList"
                      placeholder="请选择">
            </a-select>
          </a-form-item>
          <a-form-item label="双录保险公司" name="supId" v-else required>
            <a-select allow-clear show-search :filter-option="filterOption" :disabled="flag==='details'"
                      :options="typeData3" v-model:value="formData.supId" placeholder="请选择">
            </a-select>
          </a-form-item>

        </a-col>


      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose" v-show="flag==='details'">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button @click="onFormClean" v-show="flag!=='details'">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
        <a-button type="primary" @click="onSave" v-show="flag!=='details'">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {ReloadOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {message} from 'ant-design-vue'
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
// import {reloadDictionaryData} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    ReloadOutlined,
    SaveOutlined,
  },
  props: {
    typeData: {
      type: Array,
      default() {
        return []
      }
    },
    typeData1:{
      type: Array,
      default() {
        return []
      }
    },
    typeData2:{
      type: Array,
      default() {
        return []
      }
    },
    typeData3:{
      type: Array,
      default() {
        return []
      }
    },
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    handleSearch:{
      type: Function
    },
    getTypeData:{
      type: Function
    }
  },
  setup(props, {expose}) {
    const formRef = ref()
    const flag = ref()
    // watch(()=>props.status,(newVal)=>{
    //   flag.value=newVal
    //   // console.log(1111111111123232,flag.value)
    // },{
    //   immediate:true,
    //   deep:true
    // })

    const formData = reactive({
      drAccountType: '',
      drSystemName: '',
      drSystemStatus: '',
      drSystemType: '',
      id: '',
      supId: '',
      supNameList: [],
    })
    const formRules = {
      drAccountType: [{required: true,message: '请选择双录账号类型', trigger: 'change'}],//数据项
      drSystemName: [{required: true,message: '请填写双录系统名称', trigger: 'blur'}],//数据项说明
      drSystemStatus: [{required: true, trigger: 'change',message: '请选择双录系统状态' }],//数据子类型
      drSystemType: [{required: true, trigger: 'change',message: '请选择双录账号类型'}],//用户状态
      supNameList: [{required: true, trigger: 'change',message: '请选择保险公司'}],//数据类型
    }

    const state = reactive({
      statusList: [],
      title:'',
      visible: false,
      typeDatas: [],
      isNew: true,
      transferStyle: {width: '50%'},
      roleData: [],
    })

    const showDrawer = (oldData = {},f) => {
      init()
      state.title = f
      state.visible = true
      flag.value = f.indexOf('新增') !== -1 ? 'add' : (f.indexOf('查看') !== -1 ? 'details' : 'edit')
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(oldData).forEach(key => formData[key] = oldData[key])
      formData.status  = formData.status || formData.status == 0 ? formData.status + '' : undefined
    }
    const onClose = () => {
      state.visible = false
      formRef.value.clearValidate()
      Object.assign(formData, {
        drAccountType: '',
        drSystemName:'',
        drSystemStatus: '',
        drSystemType: '',
        id: '',
        supName: '',
        supNameList: [],
        supId:''
      })
      // console.log(formData)
    }
    const onFormClean = () => {
      if(state.isNew){
        formRef.value.resetFields()
      } else {
        formRef.value.clearValidate()
        Object.assign(formData, {
          drAccountType: '',
          drSystemName:'',
          drSystemStatus: '',
          drSystemType: '',
          id: '',
          supName: '',
          supNameList: [],
          supId:''
        })
      }
    }
    const value = ref()
    const onSave = async () => {
      await formRef.value.validate()
      let requestURI = getRequestURI('productDoubleRecordSystem_updateOne')
      if (state.isNew) {
        // 新增
        requestURI = getRequestURI('productDoubleRecordSystem_addOne')
      }
      request(requestURI, {data:formData}).then(
        (result) => {
          if(result.code === 1) {
            // reloadDictionaryData(formData.drSystemType)
            message.success('保存成功')
            onClose()
            formData.id = ''
            props.handleSearch('reload')
            props.getTypeData()
          }else{
            message.warning(result.message)
          }
        }
      )
    }
    const init = function() {
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['status']}}).then(res => {
        state.statusList = res.result.status
      })

    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    expose({
      showDrawer,
    })
    return {
      filterOption,
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      showDrawer,
      value,
      flag,
    }
  },
})
</script>
