<style scoped>
.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>

<template>
  <!--  搜索框-->
  <search-form :Condition="condition" :ColSpan="12" @callback="handleSearch">
  </search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增数据项
    </a-button>
    <a-button type="danger" @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    className="logListTable"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button type="primary" @click="modifyUser(record)" size="small">
          <template #icon>
            <FormOutlined />
          </template>
          修改
        </a-button>
      </template>
    </template>
  </a-table>
  <edit-user
    ref="userDrawerRef"
    :typeData="condition.type.data"
    :getTypeData="getTypeData"
    :handleSearch="handleSearch"
  ></edit-user>
</template>

<script>
import { defineComponent, reactive, computed, ref, toRefs, onMounted } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import editUser from './addData'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import { getExtendUri } from '../../../utils/request_extend'
import {
  FileAddOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons-vue'
import {message, Modal} from 'ant-design-vue'
import store from '../../../store'

export default defineComponent({
  components: {
    SearchForm,//搜索组件
    FileAddOutlined,//新增图标
    DeleteOutlined,//删除图标
    FormOutlined,//修改图标
    editUser,//修改页面
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        type: {
          label: '数据类型',
          type: 'select',
          default: '1',
          data: [],
        },
        typeName: {
          label: '类型名称',
          placeholder: '请输入类型名称',
        },
        code: '数据项',
        name: '数据描述',
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 50,
          align: 'left',
        },
        {
          title: '数据类型',
          width: 100,
          align: 'center',
          key: 'type',
          dataIndex: 'type',
        },
        {
          title: '类型名称',
          width: 120,
          align: 'center',
          key: 'typeName',
          dataIndex: 'typeName',
        },
        {
          title: '数据项',
          width: 140,
          align: 'center',
          key: 'code',
          dataIndex: 'code',
        },
        {
          title: '数据描述',   // 未找到对应字段
          width: 150,
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
        },
      ],
      selectedRowKeys: [],
      newChannel: {}
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('dictionary_selectAll'))


    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed:', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }//页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.log('handleSearch', filters)
      if (filters !== 'reload') {
        state.newChannel = filters
      }
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: state.newChannel,
      })
    }
    const addUser = function () {
      userDrawerRef.value.showDrawer()
    }
    const modifyUser = function (oldData) {
      userDrawerRef.value.showDrawer(oldData)
    }
    const deleteUser = function () {
      if (state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择信息？',
        onOk: () => {
          request(getRequestURI('dictionary_delete'), { data: { ids: state.selectedRowKeys, types: [] } }).then(res => {
            if (res.code === 1) {
              store.dispatch('dictionary/clear')
              message.success('删除成功')
              handleSearch('reload')
              getTypeData()
              state.selectedRowKeys = []
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    // 获取数据类型
    const getTypeData = () => {
      request(getExtendUri('dictionary_query_byType'),
        { data: { ids: [], types: ['System'] } }).then(
          res => {
            state.condition.type.data = res.result.System
          })
    }
    onMounted(() => {
      getTypeData()
    })

    return {
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addUser,
      modifyUser,
      deleteUser,
      getTypeData,
      userDrawerRef,
      pagination,
      loading,
      dataSource,
      getRequestURI
    }
  },
})
</script>
