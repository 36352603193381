<template>
  <a-drawer
    title="导入规则"
    :width="'65%'"
    :visible="visible"
    :mask-closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="showImportRuleDrawer(0)"
  >
    <template #extra>
      <a-button :loading="loading" type="primary" @click="saveInfo">保存</a-button>
    </template>
    <a-row :gutter="[16,16]">
      <a-col :span="4" class="rightLabel">选择文件:</a-col>
      <a-col :span="18">
        <a-upload
          v-model:file-list="fileList"
          action=""
          accept=".av"
          :showUploadList="false"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <a-button type="primary" :loading="loading">
            <upload-outlined></upload-outlined>
            上传规则(AviatorScript)文件
          </a-button>
        </a-upload>
      </a-col>
      <a-col :span="4" class="rightLabel">规则名称:</a-col>
      <a-col :span="18">
        <a-input v-model:value="ruleName"></a-input>
      </a-col>
      <a-col :span="4" class="rightLabel">规则内容:</a-col>
      <a-col :span="18" >
        <a-textarea style="resize: none;"
                    allowClear :autoSize="{minRows: 10, maxRows: 25}"
                    v-model:value="ruleContent"></a-textarea>
      </a-col>
    </a-row>

  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'

export default defineComponent({
  name: 'importRules',
  setup(props, {emit}) {
    const state = reactive({
      loading: false,
      ruleName: '',
      fileList: [],
      ruleContent: '',
      visible: false
    })
    onMounted(()=>{
      state.fileList = []
    })
    const beforeUpload = (file) => {
      const fileType = file.name.split('.').pop()
      console.log(123123,fileType)
      if(fileType !== 'av') return message.warning('文件格式不正确,请上传.av格式的文件')
      return false
    }
    const reader = new FileReader()
    reader.onload = function() {
      if(reader.result) {
        state.ruleContent = reader.result
      }
    }
    const handleChange = ({file}) => {
      const fileType = file.name.split('.').pop()
      if(fileType !== 'av') return
      if (file.name.lastIndexOf('.') > 0) {
        state.ruleName = file.name.substr(0, file.name.lastIndexOf('.'))
      } else {
        state.ruleName = file.name
      }
      console.debug('handleChange', state.fileList)
      reader.readAsText(file)
    }
    // 保存
    const saveInfo = () => {
      if (state.ruleName === '') {
        message.warning('规则名称不能为空！')
        return
      }
      if (state.ruleContent === '') {
        message.warning('规则内容不能为空！')
        return
      }
      if (!state.fileList.length) {
        message.warning('上传规则文件不能为空')
        return
      }
      state.loading = true
      request(getRequestURI('ruleScript_add'), {
        id: null,
        scriptName: state.ruleName,
        scriptText: state.ruleContent,
        originalText: state.ruleContent,
        scriptType: 0,
        collectionId: null,
        collectionName: null
      }).then(
        res => {
          state.loading = false
          console.debug(res)
          if (res.code === 1) {
            message.success('保存成功')
            emit('onSave')
            showImportRuleDrawer(0)
          } else {
            message.error(res.message)
          }
        }
      )
    }
    const showImportRuleDrawer = (type) => {
      if (type === 0) {
        state.visible = false
      } else {
        state.visible = true
        state.ruleName = ''
        state.ruleContent = ''
        state.fileList = []
      }
    }
    return {
      ...toRefs(state),
      saveInfo,
      showImportRuleDrawer,
      beforeUpload,
      handleChange
    }
  }

})
</script>

<style scoped>
.rightLabel {
  text-align: right;
}
</style>
