<style scoped>
.ant-layout {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}

.ant-layout-sider,
.ant-layout-content {
  overflow: auto;
  height: 100%;
}

.layout-split {
  display: inline-block;
  height: 100%;
  background: #e0e0e0;
  width: 5px;
  cursor: w-resize;
  z-index: 999;
  position: absolute;
}

.float-head {
}

.column-title {
  background: #e0e0e0;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.role_column {
  width: 100%;
  padding: 0 10px;
}

.role_item {
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
}

.menu_content_scroll2 {
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 4px;
}

.menu_content_scroll3 {
  height: calc(100% - 54px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
<style>
.menu_content_scroll {
  height: calc(100% - 88px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
<template>
  <a-layout style="height: 100%; position: relative" ref="layoutRef">
    <a-layout-sider theme="light" :width="width[0]">
      <div class="column-title">
        <span>菜单管理 </span>
        <span>
            <a-button v-if="haveChildFlag" shape="circle" @click="addMenu" :loading="loading">
              <template #icon> <PlusOutlined/> </template>
            </a-button> <a-button v-if="isAction" shape="circle" @click="addAction" :loading="loading">
              <template #icon> <ThunderboltFilled/> </template>
            </a-button>
          </span>
      </div>
      <a-input-search v-model:value="searchValue" style="margin-bottom: 8px" placeholder="这里可以输入关键词，查询菜单."/>
      <a-tree class="menu_content_scroll" @expand="onExpand"
              v-model:expanded-keys="expandedKeys"
              :auto-expand-parent="autoExpandParent" :tree-data="menus_data" :show-icon="true" @select="treeSelected">
        <template #title="{ title }">
          <span v-if="title.indexOf(searchValue) > -1">
            {{ title.substr(0, title.indexOf(searchValue)) }}<span style="color: #f50">{{
              searchValue
            }}</span>{{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
          </span>
          <span v-else>{{ title }}</span>
        </template>
        <template #icon="{ iconStr, isMenu }">
          <component v-if="isMenu==='Y'" :is="iconComponent(iconStr)"/>
          <component v-else :is="iconComponent(iconStr)"/>
        </template>
      </a-tree>
    </a-layout-sider>
    <div class="layout-split" :style="{ left: width[0] + 'px' }" @mousedown="(event) => startDrag(event, 0, this)">
    </div>
    <a-layout-sider theme="light" :width="width[1]" :style="{ 'margin-left': '5px' }">
      <div class="column-title">
        <span>内容详情</span>
        <span>
          <a-button shape="circle" @click="saveMenuOrAction" :loading="loading">
            <template #icon> <CheckOutlined/> </template>
          </a-button> <a-button shape="circle" danger type="primary" @click="delConfirm" :loading="loading">
            <template #icon> <DeleteOutlined/> </template>
          </a-button>
        </span>
      </div>
      <div class="menu_content_scroll2">
        <a-form :model="formData" :rules="rules" ref="formRef" layout="vertical">
          <a-input v-model:value="formData.id" v-show="false"/>
          <a-input v-if="parentMenu" v-model:value="parentMenu.id" v-show="false"/>
          <a-form-item label="上级名称" name="parentMenu">
            <a-input v-if="parentMenu" v-model:value="parentMenu.name" placeholder="上级名称" disabled/>
          </a-form-item>
          <a-form-item label="名称" name="name">
            <a-input v-model:value="formData.name" placeholder="名称"/>
          </a-form-item>
          <a-form-item :label="isShowCode?'请求地址':'页面地址'" name="uri">
            <a-input v-model:value="formData.uri" placeholder="请求地址"/>
          </a-form-item>
          <a-form-item v-show="isShowCode" label="编号" name="code">
            <a-input v-model:value="formData.code" placeholder="编号"/>
          </a-form-item>
          <a-form-item label="本级排序" name="orderFlag">
            <a-input-number v-model:value="formData.orderFlag" :min="0" :max="999" style="width: 100%"/>
          </a-form-item>
          <a-form-item v-show="!isShowCode" label="菜单状态" name="status">
            <a-select v-model:value="formData.status" placeholder="请选择" :options="statusList">
            </a-select>
          </a-form-item>
          <a-form-item v-show="!isShowCode" label="菜单展示" name="showFlag">
            <a-select v-model:value="formData.showFlag" placeholder="请选择" :options="showFlagList">
            </a-select>
          </a-form-item>
          <a-form-item label="图标" name="icon">
            <a-select v-model:value="formData.icon" placeholder="请选择图标" :options="iconList" showSearch>
              <template #option="{ value: val, label }">
                <component :is="iconComponent(val)"/>
                -- {{ label }}
              </template>
              <template #suffixIcon>
                <component :is="iconComponent(formData.icon)"/>
              </template>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-layout-sider>
    <div class="layout-split" :style="{ left: width[0] + width[1] + 5 + 'px' }"
         @mousedown="(event) => startDrag(event, 1, this)"></div>
    <a-layout-content :style="{ 'margin-left': '5px' }">
      <div class="column-title">
        <span>角色列表</span>
        <span>
          <a-button shape="circle" :loading="loading" @click="saveMenuForRole">
            <template #icon><CheckOutlined/></template>
          </a-button>
        </span>
      </div>
      <a-checkbox-group v-model:value="selectRoles" class="role_column menu_content_scroll3">
        <template v-for="item in roleInfos" :key="'role_' + item['id']">
          <a-checkbox class="role_item" :value="item['id']">{{ item['roleName'] }}</a-checkbox>
        </template>
      </a-checkbox-group>
    </a-layout-content>
  </a-layout>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, watch, onMounted} from 'vue'
import {message, Modal} from 'ant-design-vue'
import * as antIcons from '@ant-design/icons-vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryData} from '../../../utils/request_extend'

export default defineComponent({
  components: {},
  setup() {
    const layoutRef = ref()
    // --- icon ---
    let _Filled = [], _Outlined = [], _TwoTone = []
    Object.keys(antIcons).forEach((key) => {
      if (key && key.endsWith('Filled')) {
        _Filled.push({
          value: key,
          label: key
        })
      } else if (key && key.endsWith('Outlined')) {
        _Outlined.push({
          value: key,
          label: key
        })
      } else if (key && key.endsWith('TwoTone')) {
        _TwoTone.push({
          value: key,
          label: key
        })
      }
    })
    // --- icon end ---
    const expandedKeys = ref([])
    const searchValue = ref('')
    const autoExpandParent = ref(true)
    const state = reactive({
      isDrag: false,
      width: [300, 400], //初始宽度
      minWidth: 160, //最小宽度
      dragTarget: 0,
      dragX: 0,
      originalX: 0,
      loading: false,
      // menu tree
      menus_data: [],
      selectedMenu: {},
      isAction: false,
      haveChildFlag: true,
      // menu form
      params: {},
      parentMenu: {},
      formData: {orderFlag: 1, showFlag: 'Y', status: '1'},
      rules: [],

      isShowCode: false,
      // menu role
      selectRoles: [],
      roleInfos: [],
      menu: {},
      // icon
      iconList: [{
        label: '实底图标',
        options: _Filled
      }, {
        label: '线框图标',
        options: _Outlined
      }, {
        label: '双色图标',
        options: _TwoTone
      }]
    })
    // --- drag start ---
    const startDrag = (event, target) => {
      if (event.button === 0) {
        state.dragTarget = target
        state.dragX = event.clientX
        state.originalX = state.width[target]
        state.isDrag = true
      }
    }
    const drag = (event) => {
      if (event.button === 0 && state.isDrag === true) {
        event.preventDefault()
        let newWidth = state.originalX + event.clientX - state.dragX
        const maxWidth =
          layoutRef.value.$el.clientWidth -
          10 -
          state.minWidth -
          state.width[1 - state.dragTarget]
        state.width[state.dragTarget] =
          newWidth < state.minWidth ?
            state.minWidth :
            newWidth > maxWidth ?
              maxWidth :
              newWidth
      }
    }
    const stopDrag = () => {
      state.isDrag = false
    }
    onMounted(() => {
      layoutRef.value.$el.addEventListener('mouseup', stopDrag)
      layoutRef.value.$el.addEventListener('mousemove', drag)
    })
    // --- drag end ---

    const iconComponent = (name) => {
      return antIcons[name]
    }
    const treeSelected = (selectedKeys, {node: _menu}) => {
      if (selectedKeys.length === 0) {
        state.selectedMenu = {}
        state.isAction = false
        state.haveChildFlag = true
      } else {
        state.selectedMenu = _menu
        state.isAction = (_menu.isMenu === 'Y')
        state.haveChildFlag = (_menu.isMenu === 'Y') ? (_menu.childFlag === 'Y') : false
      }
      loadRole(state.selectedMenu)
      let parentId = _menu.isMenu === 'N' ? _menu.menuId : _menu.parentId
      state.parentMenu = {
        id: parentId,
        title: parentId !== '0' ? _menu.parentName : '菜单',
        name: parentId !== '0' ? _menu.parentName : '菜单'
      }
      state.formData = {
        id: state.selectedMenu.id,
        name: state.selectedMenu.name,
        uri: state.selectedMenu.uri,
        code: state.selectedMenu.code,
        orderFlag: state.selectedMenu.orderFlag,
        // showFlag: state.selectedMenu.showFlag + '',
        showFlag: state.selectedMenu.showFlag,
        status: state.selectedMenu.status,
        icon: state.selectedMenu.iconStr,
        isMenu: state.selectedMenu.isMenu
      }
      state.isShowCode = (state.selectedMenu.isMenu === 'N')
    }
    const addAction = () => {
      if (state.selectedMenu && state.selectedMenu.id) {
        state.isShowCode = true
        state.parentMenu = state.selectedMenu
        state.formData = {orderFlag: 1, showFlag: 'Y', status: '1'}
        loadRole({})
      } else {
        message['warning']('请选择一条菜单')
      }
    }
    const addMenu = () => {
      state.isShowCode = false
      state.formData = {orderFlag: 1, showFlag: 'Y', status: '1'}
      loadRole({})
      if (state.selectedMenu && state.selectedMenu.id) {
        state.parentMenu = state.selectedMenu
      } else {
        state.parentMenu = {
          id: '0',
          title: '菜单',
          name: '菜单'
        }
      }
    }
    const getMenu = (_e, _pName) => {
      if (_e && _e.length > 0) {
        let arr = []
        _e.forEach(e => {
          arr.push({
            id: e.id,
            key: e.id,
            title: e.name,
            iconStr: e.isMenu === 'Y' ? e.icon || 'MenuOutlined' : e.icon || 'ThunderboltFilled',
            uri: e.uri,
            name: e.name,
            code: e.code,
            parentId: e.parentId,
            parentName: _pName,
            isMenu: e.isMenu,
            menuId: e.menuId,
            childFlag: e.childFlag,
            isLeaf: e.isMenu !== 'Y',
            orderFlag: e.orderFlag,
            showFlag: e.showFlag=== 'N' ? 'N' : 'Y',
            status: e.status + '',
            children: getMenu(e.submenus || e.actions, e.name),
            actions: e.actions
          })
          dataList.push({
            key: e.id,
            title: e.name
          })
        })
        return arr
      } else {
        return []
      }
    }
    const getParentKey = (key, tree) => {
      let parentKey
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
          if (node.children.some(item => item.key === key)) {
            parentKey = node.key
          } else if (getParentKey(key, node.children)) {
            parentKey = getParentKey(key, node.children)
          }
        }
      }
      return parentKey
    }
    const onExpand = keys => {
      expandedKeys.value = keys
      autoExpandParent.value = false
    }
    const dataList = []
    watch(searchValue, value => {
      if (value.length > 0) {
        expandedKeys.value = dataList.map(item => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, state.menus_data)
          }
          return null
        }).filter((item, i, self) => item && self.indexOf(item) === i)
      }
      searchValue.value = value
      autoExpandParent.value = true
    })
    const saveMenuOrAction = () => {
      let uri, param
      if (state.isShowCode) {
        if (state.formData.id && state.formData.id !== '') {
          uri = getRequestURI('menu_action_update')
        } else {
          uri = getRequestURI('menu_action_save')
        }
        param = {
          id: state.formData.id,
          menuId: state.parentMenu.id,
          code: state.formData.code,
          name: state.formData.name,
          icon: state.formData.icon,
          orderFlag: state.formData.orderFlag,
          uri: state.formData.uri
        }
      } else {
        if (state.formData.id && state.formData.id !== '') {
          uri = getRequestURI('menu_update')
        } else {
          uri = getRequestURI('menu_save')
        }
        param = {
          id: state.formData.id,
          parentId: state.parentMenu.id,
          childFlag: (!state.formData.uri || state.formData.uri === '') ? 'Y' : 'N',
          orderFlag: state.formData.orderFlag,
          name: state.formData.name,
          icon: state.formData.icon,
          uri: state.formData.uri,
          status: state.formData.status,
          showFlag: state.formData.showFlag
        }
      }
      state.loading = true
      return request(uri, {data: param}).then(result => {
        state.loading = false
        if (result.code === 1) {
          message['success']('保存成功')
          init()
        } else {
          message['error'](result.message)
        }
      })
    }
    const delConfirm = () => {
      if (!state.formData.id || state.formData.id === '') {
        message['error']('请选择需要删除的菜单或操作！')
      } else {
        Modal.confirm({
          title: '删除确认',
          content: '是否删除',
          onOk: () => {
            delMenuOrAction()
          },
          onCancel: () => {
            Modal.destroyAll()
          }
        })
      }
    }
    const delMenuOrAction = () => {
      let uri
      let param = {id: state.formData.id}
      if (state.isShowCode) {
        uri = getRequestURI('menu_action_delete')
      } else {
        uri = getRequestURI('menu_delete')
      }
      return request(uri, {data: param}).then(result => {
        if (result.code === 1) {
          init()
          state.formData = {orderFlag: 1, showFlag: 'Y', status: '1'}
          message['success']('删除成功')
        } else {
          message['error'](result.message)
        }
      })
    }

    const loadRole = (_menu) => {
      state.menu = _menu
      state.roleInfos = []
      state.selectRoles = []
      if (state.menu && state.menu.id) {
        let param = {data: {menuId: state.menu.id}}
        let uri = getRequestURI('menu_role_query')
        if (state.menu.isMenu === 'N') {
          param = {data: {actionId: state.menu.id}}
          uri = getRequestURI('action_role_query')
        }
        state.loading = true
        request(uri, param).then(res => {
          state.loading = false
          if (res.code === 1) {
            state.roleInfos = res.result
            res.result.forEach(r => {
              if (r['roleStatus'] === 1) {
                state.selectRoles.push(r.id)
              }
            })
          }
        })
      }
    }
    const saveMenuForRole = () => {
      if (state.menu && state.menu.id) {
        let list = []
        state.selectRoles.forEach(id => {
          list.push({
            roleId: id,
            actionId: (state.menu.isMenu === 'N' ? state.menu.id : ''),
            menuId: (state.menu.isMenu === 'Y' ? state.menu.id : state.menu.menuId),
            isMenu: state.menu.isMenu
          })
        })
        let param, uri
        if (state.menu.isMenu === 'N') {
          uri = getRequestURI('action_role_save')
          param = {
            actionId: state.menu.id,
            sourceList: list
          }
        } else {
          param = {
            menuId: state.menu.id,
            sourceList: list
          }
          uri = getRequestURI('menu_role_save')
        }
        state.loading = true
        return request(uri, {data: param}).then(result => {
          state.loading = false
          if (result) {
            if (result.code === 1) {
              loadRole(state.menu)
              message['success']('保存成功')
            } else {
              message['error'](result.message)
            }
          }
        })
      }
    }
    const statusList = ref([])
    const showFlagList = ref([])
    const init = () => {
      getDictionaryData(statusList, 'status')
      getDictionaryData(showFlagList, 'yes_no')
      request(getRequestURI('menu_query_all'), {}).then(res => {
        if (res.code === 1) {
          res.result.forEach(item => {
            item.showFlag = item.showFlag + ''
            // console.log(item.showFlag)
          })

          dataList.length = 0
          state.menus_data.length = 0
          state.menus_data = getMenu(res.result, '菜单')
          // console.log(state.menus_data)
        }
      })
    }
    init()
    return {
      searchValue,
      expandedKeys,
      autoExpandParent,
      ...toRefs(state),
      onExpand,
      startDrag,
      drag,
      stopDrag,
      layoutRef,
      iconComponent,
      treeSelected,
      addAction,
      addMenu,
      saveMenuOrAction,
      delConfirm,
      saveMenuForRole,
      statusList,
      showFlagList,
    }
  }
})

</script>
